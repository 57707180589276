import React, { Component } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { Link } from "react-router-dom";

import ServersImage_1 from "../../../assets/images/crypto_image_3.jpg";
import ServersImage_2 from "../../../assets/images/crypto_image_4.avif";
import ServersImage_3 from "../../../assets/images/crypto_image_5.png";

import { AnimationOnScroll } from "react-animation-on-scroll";

import "./security.css";

interface SecurityBannerProps {}

interface SecurityBannerState {}

export default class SecurityBanner extends Component<SecurityBannerProps, SecurityBannerState> {
  dataInterval: any;

  constructor(props: SecurityBannerProps) {
    super(props);

    this.state = {};
  }
  componentDidMount(): void {}

  render() {
    return (
      <div className="ct-info-banners-container">
        <ul className="ct-circles-animation">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>

        <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce>
          <div className="ct-security-banner-container ct-max-container">
            <img src={ServersImage_2} alt="Servers maintenance" />
            <div className="ct-text-container">
              <h4>Save your time</h4>
              <p>We can automate everything</p>
              <p>
                Decentralized finance (DeFi) is a rapidly growing industry that is changing the way we think about money and financial services. At
                its core, DeFi is all about creating financial products and services that are accessible to anyone, anywhere in the world, without the
                need for traditional banking institutions. And one of the key tools that is driving this revolution is automation.
              </p>
              <button className="button-29 ct-mt-20">
                <Link to="/pools">Invest now</Link>{" "}
              </button>
            </div>
          </div>
        </AnimationOnScroll>

        <AnimationOnScroll animateIn="animate__fadeInLeft" animateOnce>
          <div className="ct-security-banner-container ct-max-container">
            <div className="ct-text-container">
              <h4>Work with the community</h4>
              <p>Use our platform to work together</p>
              <p>
                Our goal is to make it possible for many people to have a great reach in this area, to invest in projects that you would never have
                imagined using the power of our community. We want to create a solid future for small investors to invest in giant projects.
              </p>
              <button className="button-29 ct-mt-20">
                <Link to="/pools">Invest now</Link>{" "}
              </button>
            </div>
            <img src={ServersImage_1} alt="Servers maintenance" />
          </div>
        </AnimationOnScroll>

        <AnimationOnScroll animateIn="animate__fadeInRight" animateOnce>
          <div className="ct-security-banner-container ct-max-container">
            <img src={ServersImage_3} alt="Servers maintenance" />
            <div className="ct-text-container">
              <h4>Save on maintenance costs</h4>
              <p>We make everything for you</p>
              <p>
                At our company, we understand that maintenance costs can be a significant burden for many users. That's why we're committed to finding
                innovative solutions to help reduce those costs and make our products more affordable for everyone.
              </p>
              <button className="button-29 ct-mt-20">
                <Link to="/pools">Invest now</Link>{" "}
              </button>
            </div>
          </div>
        </AnimationOnScroll>

      </div>
    );
  }
}
