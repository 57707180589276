import React from "react";

import { BlockChainState } from "../../storage/state/blockChain/state";
import { ApplicationState } from "../../storage/state/app/state";
import { AppErrorCode } from "../../core/app";
import { DynamicPool } from "../organisms/pool/dynamic";

interface DynamicPoolComponentProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadBlockChain: () => void;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
  setAvailableNetworks: (networks: string[]) => void;
  setProviderError: (error: boolean) => void;
  setNetworksError: (error: boolean) => void;
}

interface DynamicPoolComponentState {}

export class DynamicPoolPage extends React.PureComponent<DynamicPoolComponentProps, DynamicPoolComponentState> {
  constructor(props: DynamicPoolComponentProps) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {
    this.props.onToggleLoader(false);
  }

  componentWillUnmount() {}

  render() {
    return (
      <React.Fragment>
        <div className="ct-pool-page ct-mt-50">
          <ul className="ct-circles-animation">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>

          <div className="ct-available-pools ct-max-container">
            <div className="ct-header ct-max-container">
              <h4>Pool</h4>
              <small>Invest on projects like a institution collaborating with more users.</small>
            </div>
            <DynamicPool
              appState={this.props.appState}
              setAvailableNetworks={(networks) => this.props.setAvailableNetworks(networks)}
              setNetworksError={(error) => this.props.setNetworksError(error)}
              setProviderError={(error) => this.props.setProviderError(error)}
              onLoadBlockChain={() => this.props.onLoadBlockChain()}
              onLoadCustomerData={() => this.props.onLoadCustomerData(true)}
              blockChain={this.props.blockChain}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
