import "./waves.css";

export const Waves = () => {
  return (
    <div className="ct-waves">
      <div className="ocean">
        <div className="wave"></div>
        <div className="wave"></div>
      </div>
      <div className="ct-ocean-continuation"></div>
      <div className="ocean">
        <div className="wave"></div>
        <div className="wave"></div>
      </div>
    </div>
  );
};
