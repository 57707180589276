import { toast } from "react-toastify";
import { BlockChainState } from "../storage/state/blockChain/state";
import { AppErrorCode, Contract } from "./app";
import { BlockChain } from "./chain";
import { BlockChainHelpers } from "./helpers/chain";
import { UtilsHelpers } from "./helpers/utils";
import { AppData } from "./types";
import { ApiHelpers } from "./helpers/api";

export class Customer {
  constructor(public _address: string | null, public appDataModel: AppData) {}

  get tokensData() {
    return this.appDataModel.customerToken;
  }

  //BlockChain

  static async loadCustomerData(blockChain: BlockChain): Promise<{
    customer: null | Customer;
    error: AppErrorCode | null;
    appData: AppData | null;
  }> {
    UtilsHelpers.debugger("Load customer Data.");

    try {
      if (blockChain.selectedAccount) {
        const mainTokenBalance = UtilsHelpers.normalizeWei((await BlockChainHelpers.getMainTokenBalance(blockChain.selectedAccount)) || "0");

        // const customerProfile = await ApiHelpers.getUserProfile(
        //   blockChain.selectedAccount
        // );

        const appDataModel: AppData = AppData.buildFromTemplates(
          {
            mainTokenBalance,
          },
          {}
        );

        return {
          customer: new Customer(blockChain.selectedAccount, appDataModel),
          error: null,
          appData: appDataModel,
        };
      } else {
        toast.error("[ERROR] " + AppErrorCode.INVALID_CONTRACT_LOADING);

        return {
          error: AppErrorCode.INVALID_CONTRACT_LOADING,
          customer: null,
          appData: null,
        };
      }
    } catch (error) {
      toast.error("[ERROR] " + AppErrorCode.INVALID_CONTRACT_LOADING);

      return {
        error: AppErrorCode.INVALID_CONTRACT_LOADING,
        customer: null,
        appData: null,
      };
    }
  }

  //Storage

  static setCustomer(state: BlockChainState, customer: Customer) {
    return { ...state, customer };
  }

  static clearCustomer(state: BlockChainState) {
    return { ...state, customer: null };
  }
}
