export enum AppMode {
  DEV = "dev",
  PRO = "pro",
  METER = "meter",
  TEST = "test",
  TEST_METER = "test-meter",
  TEST_POLYGON = "test-polygon",
  POLYGON = "polygon",
}

export enum AppChain {
  BSC = "bsc",
  BSCT = "bsc-test",
  METER = "meter",
  METER_TEST = "meter-test",
  POLYGON_TEST = "polygon-test",
  POLYGON = "polygon",
  GANACHE = "ganache",
}

export enum Contract {
  CROWD_QUERIES = "crowd-queries",
  CROWD_FUNDING = "crowd-funding",
}

export enum AppErrorCode {
  BAD_REQUEST = "bad_request",
  INCORRECT_BLOCKCHAIN_NETWORK = "incorrect-block-chain-network",
  INVALID_PROVIDER = "invalid-provider",
  INVALID_CONTRACT_LOADING = "invalid-contract-loading",
  // NFT errors
  MINT_IS_NOT_VALID = "mint-is-not-valid",
  RESET_DEPLOYMENTS_ERROR = "reset-deployments-error",
  MINT_ERROR = "mint-error",
  ADD_EMPLOYEE_TO_FACTORY = "add-employee-to-factory-error",
  BURN_ERROR = "burn-error",
  UPGRADE_ERROR = "upgrade-error",
  GROW_ERROR = "grow-error",
  GROW_ALL_ERROR = "grow-all-error",
  CANT_GROW_ERROR = "cant-grow-error",
  CANT_GROW_ALL_ERROR = "cant-grow-all-error",
  HAVENT_UPGRADES = "havent-upgrades",
  ADD_TO_WHITELIST = "add-to-whitelist",
  ON_SAVE_MINTER_POINTS = "on-save-minter-points",
  // App errors
  USER_DENIED_TRANSACTION = "user-denied-transaction",
  INVALID_APPROVED_SPEND = "invalid-approved-spend",
  INVALID_TESTNET_REQUEST = "invalid-testnet-request",
  //Connections errors
  ADD_MODEL_ERROR = "add-model-error",
  ADD_TYPE_ERROR = "add-type-error",
  REMOVE_MODEL_ERROR = "remove-model-error",
  REMOVE_TYPE_ERROR = "remove-type-error",
  SEND_EMPLOYEE_TO_WORK_ERROR = "send-employee-to-work-error",
  //Employee staking
  SEND_EMPLOYEE_TO_STAKING = "send-employee-to-staking",
  REMOVE_EMPLOYEE_FROM_STAKING = "remove-employee-from-staking",
  TAKE_CUSTOMER_REWARDS = "take-customer-rewards",
  //Factory staking
  SEND_FACTORY_TO_STAKING = "send-factory-to-staking",
  REMOVE_FACTORY_FROM_STAKING = "remove-factory-from-staking",
  SEND_CUSTOMER_STAKING_REWARDS = "send-customer-staking-rewards",
  INVALID_POINTS = "invalid-points",
  //Token
  APPROVE_SPEND = "approved-spend",
  INCREASE_ALLOWANCE = "increase-allowance",
  //Employees
  BURN_EMPLOYEE = "burn-employee",
  APPROVE_EMPLOYEE = "approve-employee",
  SAFE_TRANSFER_FROM = "safe-transfer-from",
  //Marketplace
  SELL_ERROR = "sell-error",
  BUY_ERROR = "buy-error",
  CANCEL_SELL_ERROR = "cancel-sell-error",
  //Game
  REQUEST_TOKENS = "request-tokens",
  PLAY_ERROR = "play-error",
  //Builders
  REQUEST_ERROR = "request-error",
  //Bridge
  MINTER_REQUEST_ERROR = "request-error",
  LIMBO_REQUEST_ERROR = "limbo-error",
  //Battles
  GENERATE_RANDOM_STATS = "generate-random-stats",
}

export const ULTRA_ERRORS = [AppErrorCode.INVALID_PROVIDER, AppErrorCode.INCORRECT_BLOCKCHAIN_NETWORK, AppErrorCode.INVALID_CONTRACT_LOADING];

export const appConfig = {
  mode: AppMode.TEST_POLYGON,
  open: true,
  confirmedBlocks: 0,
  blocktimeout: 1000,
  fastTransaction: "500",
  explorer: "https://mumbai.polygonscan.com/",
  API: "https://back.blockraising.xyz", // https://test.back.blockraising.xyc http://localhost:3090
  devWallet: [
    "0xe5A8449Fb27bdC1dfEd3C94258EEa75c96e8852f".toLocaleLowerCase(),
    "0xcbB70C0a699401B6E9cEF35d6f215924CEa4b4D4".toLocaleLowerCase(),
    "0x284770bd795Dfc02b4248141f032A888DAFF9Bea".toLocaleLowerCase(),
    "0x97Ef9d046295b35F43458CE94C0eE9204767fA9A".toLocaleLowerCase(),
  ],
};

export interface AppError {
  message: string;
  code: AppErrorCode;
}

export interface Currency {
  name?: string;
  symbol?: string;
  decimals?: number;
}

export interface ChainData {
  chainId: string;
  chainName?: string;
  nativeCurrency?: Currency;
  rpcUrls?: string[];
  blockExplorerUrls?: string[] | null;
}

export type PosibleChainsType = {
  [mode in AppChain]: ChainData;
};

export type PosibleContractAddress = {
  [mode in AppMode]:
    | {
        [contract in Contract]: string | null;
      }
    | null;
};

export const artHost = {
  [AppMode.DEV]: "https://art.businessbuilders.city/static",
  [AppMode.TEST]: "https://art.businessbuilders.city/static",
  [AppMode.PRO]: "https://art.businessbuilders.city/static",
  [AppMode.METER]: "https://art.businessbuilders.city/static",
  [AppMode.TEST_METER]: "https://art.businessbuilders.city/static",
};

export const posibleContractAddress: PosibleContractAddress = {
  [AppMode.DEV]: null,
  [AppMode.TEST]: null,
  [AppMode.PRO]: null,
  [AppMode.METER]: null,
  [AppMode.TEST_METER]: null,
  [AppMode.TEST_POLYGON]: {
    [Contract.CROWD_FUNDING]: "0xE8c5dF413f0aEcbb64114B88d9C094052Dcc66D4",
    [Contract.CROWD_QUERIES]: "0x92294B5B6ce593f0c32C5530565E2ad191eDCac9",
  },
  [AppMode.POLYGON]: null,
};

export const relatedModeToChain: { [key in AppChain]: AppMode } = {
  [AppChain.BSC]: AppMode.PRO,
  [AppChain.METER]: AppMode.METER,
  [AppChain.POLYGON]: AppMode.POLYGON,
  [AppChain.BSCT]: AppMode.TEST,
  [AppChain.GANACHE]: AppMode.DEV,
  [AppChain.METER_TEST]: AppMode.TEST_METER,
  [AppChain.POLYGON_TEST]: AppMode.TEST_POLYGON,
};

export const posibleChains: PosibleChainsType = {
  [AppChain.BSC]: {
    chainId: "0x38",
    chainName: "BNB Chain",
    nativeCurrency: {
      symbol: "BNB",
      name: "Binance",
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed.binance.org/"],
    blockExplorerUrls: ["https://bscscan.com"],
  },
  [AppChain.BSCT]: {
    chainId: "0x61",
    chainName: "BNB Testnet",
    nativeCurrency: {
      symbol: "BNB",
      name: "Binance",
      decimals: 18,
    },
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
    blockExplorerUrls: ["https://testnet.bscscan.com"],
  },
  [AppChain.GANACHE]: {
    chainId: "0x7a69",
    chainName: AppChain.GANACHE,
    nativeCurrency: {
      symbol: "MATIC",
      name: "Matic",
      decimals: 18,
    },
    rpcUrls: ["http://localhost:8545"],
    blockExplorerUrls: ["http://localhost:8545"],
  },
  [AppChain.METER_TEST]: {
    chainId: "0x53",
    chainName: AppChain.METER_TEST,
    nativeCurrency: {
      symbol: "TMTRG",
      name: "METER",
      decimals: 18,
    },
    rpcUrls: ["https://rpctest.meter.io"],
    blockExplorerUrls: ["https://scan-warringstakes.meter.io"],
  },
  [AppChain.POLYGON_TEST]: {
    chainId: "0x13881",
    chainName: "Mumbai",
    nativeCurrency: {
      symbol: "MATIC",
      name: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
    blockExplorerUrls: ["https://mumbai.polygonscan.com"],
  },
  [AppChain.METER]: {
    chainId: "0x52",
    chainName: AppChain.METER,
    nativeCurrency: {
      symbol: "MTRG",
      name: "METER",
      decimals: 18,
    },
    rpcUrls: ["https://rpc.meter.io"],
    blockExplorerUrls: ["https://scan.meter.io"],
  },
  [AppChain.POLYGON]: {
    chainId: "0x89",
    chainName: "Polygon",
    nativeCurrency: {
      symbol: "MATIC",
      name: "MATIC",
      decimals: 18,
    },
    rpcUrls: ["https://polygon-rpc.com"],
    blockExplorerUrls: ["https://polygonscan.com"],
  },
};

export const posibleChainsData = {
  [AppChain.BSC]: {
    chainId: "0x38",
    chainName: "BNB Chain",
    symbol: "BNB",
  },
  [AppChain.BSCT]: {
    chainId: "0x61",
    chainName: "BNB Testnet",
    symbol: "BNB",
  },
  [AppChain.GANACHE]: {
    chainId: "0x7a69",
    chainName: AppChain.GANACHE,
    symbol: "ETH",
  },
  [AppChain.METER_TEST]: {
    chainId: "0x53",
    chainName: AppChain.METER_TEST,
    symbol: "MTR",
  },
  [AppChain.POLYGON_TEST]: {
    chainId: "0x13881",
    chainName: "Mumbai",
    symbol: "MUMBAI",
  },
  [AppChain.METER]: {
    chainId: "0x52",
    chainName: AppChain.METER,
    symbol: "MTR",
    rpcUrls: ["https://rpc.meter.io"],
    blockExplorerUrls: ["https://scan.meter.io"],
  },
  [AppChain.POLYGON]: {
    chainId: "0x89",
    chainName: "Polygon",
    symbol: "MATIC",
  },
};
