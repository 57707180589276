import CryptoImage from "../../../assets/images/crypto_image.webp";
import { Waves3 } from "../../molecules/waves/waves3";
import { Link } from "react-router-dom";

import "./descBanner.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

interface DescBannerProps {}

export const DescBannerComponent = (props: DescBannerProps) => {
  return (
    <div className="ct-info-banner-container">
      <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce>
        <div className="ct-data-container ct-max-container">
          <div className="ct-info-banner-text">
            <h1>Dencentralized finances</h1>
            <p>
              Our decentralized finance project is designed to offer users a secure, reliable and profitable way to manage their finances. By
              leveraging the power of blockchain technology, we provide a decentralized financial system that eliminates the need for intermediaries,
              ensuring that your funds are secure and always accessible to you.
            </p>
            <button className="button-29 ct-mt-20">
              <Link to="/pools">Pools</Link>
            </button>
          </div>
          <img className="ct-info-banner-image" src={CryptoImage} alt="" />
        </div>
      </AnimationOnScroll>
      <Waves3 />
    </div>
  );
};

export default DescBannerComponent;
