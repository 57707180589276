import React from "react";

import { BlockChainState } from "../../storage/state/blockChain/state";
import { ApplicationState } from "../../storage/state/app/state";
import { AppErrorCode } from "../../core/app";
import { ApiHelpers } from "../../core/helpers/api";
import { APIPoolDataInterface } from "../../core/types";
import { PoolsList } from "../organisms/pool/list";

interface PoolsComponentProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadBlockChain: () => void;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

interface PoolsComponentState {
  pools: APIPoolDataInterface[];
}

export class PoolsPage extends React.PureComponent<PoolsComponentProps, PoolsComponentState> {
  constructor(props: PoolsComponentProps) {
    super(props);

    this.state = {
      pools: [],
    };
  }

  async componentDidMount() {
    this.loadPoolsInformation();
    this.props.onToggleLoader(false);
  }

  async loadPoolsInformation() {
    ApiHelpers.getPools()
      .then((data) => {
        if (data && !data.error && Array.isArray(data.data)) {
          this.setState({
            pools: [...data.data],
          });
        } else throw new Error("Invalid API information");
      })
      .catch((error) => console.log(error));
  }

  componentWillUnmount() {}

  componentDidUpdate(prevProps: Readonly<PoolsComponentProps>, prevState: Readonly<PoolsComponentState>, snapshot?: any): void {
    if (!prevProps.blockChain.controller?.selectedAccount && this.props.blockChain.controller?.selectedAccount) {
      this.loadPoolsInformation();
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="ct-pool-page ct-mt-50">
          <ul className="ct-circles-animation">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>

          <div className="ct-available-pools ct-max-container">
            <div className="ct-header ct-max-container">
              <h4>Available pools</h4>
              <small>Invest on projects like a institution collaborating with more users.</small>
            </div>
            <PoolsList blockChain={this.props.blockChain} pools={this.state.pools} />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
