import React, { lazy, Suspense } from "react";
import Dropzone from "react-dropzone";
import { Link } from "react-scroll";

import { BlockChainState } from "../../storage/state/blockChain/state";
import { ApplicationState } from "../../storage/state/app/state";
import { TokenController } from "../../core/modules/token";
import { UtilsHelpers } from "../../core/helpers/utils";
import { appConfig, AppErrorCode, Contract } from "../../core/app";
import { ApiHelpers } from "../../core/helpers/api";
import { toast } from "react-toastify";
import { LazyLoadComponent, LazyLoadImage } from "react-lazy-load-image-component";
import { MainBannerComponent } from "../organisms/banners/mainBanner";
import SwipeToSlide from "../organisms/sliders/sliderOne";
import StatsBanner from "../organisms/banners/statsBanner";
import { Footer } from "../organisms/navigation/footer";
import DescBannerComponent from "../organisms/banners/descBanner";
import SecurityBanner from "../organisms/banners/security";
import LaunchedProjects from "../organisms/banners/launchedProjects";
import { FullTimeline } from "../organisms/timeline/main";

interface MainPageComponentProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadBlockChain: () => void;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
}

interface MainPageComponentState {}

export class MainPage extends React.PureComponent<MainPageComponentProps, MainPageComponentState> {
  constructor(props: MainPageComponentProps) {
    super(props);

    this.state = {};
  }

  async componentDidMount() {
    this.props.onToggleLoader(false);
  }

  componentWillUnmount() {}

  componentDidUpdate() {}

  render() {
    return (
      <div className="ct-main-page">
        <MainBannerComponent />
        <DescBannerComponent />
        <StatsBanner />
        <SecurityBanner />
        <LaunchedProjects />
        <SwipeToSlide />
        <FullTimeline></FullTimeline>
      </div>
    );
  }
}
