import { ContractBaseModel } from "../abstracts/contract";
import { AbstractCrowdFunding } from "../abstracts/crowdFunding";
import { AppErrorCode } from "../app";

export class CrowdFunding extends ContractBaseModel<AbstractCrowdFunding> {
  getContractInfo(user: string) {
    return this.contract.getContractInfo(user).call(this.config);
  }

  hasCycleVote(user: string, cycle: number) {
    return this.contract.hasCycleVote(user, cycle).call(this.config);
  }

  hasVotesOnTheCycle(user: string) {
    return this.contract.hasVotesOnTheCycle(user).call(this.config);
  }

  getUserInfo(user: string) {
    return this.contract.getUserInfo(user).call(this.config);
  }

  getPoolInfo() {
    return this.contract.getPoolInfo().call(this.config);
  }

  getAvailableProfit(user: string) {
    return this.contract.getAvailableProfit(user).call(this.config);
  }

  closePool(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .closePool()
      .send({ ...this.config })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.APPROVE_SPEND, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.APPROVE_SPEND, error, callback));
  }

  withdrawDevelopersProfit(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .withdrawDevelopersProfit()
      .send({ ...this.config })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.APPROVE_SPEND, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.APPROVE_SPEND, error, callback));
  }

  withdrawMakerProfit(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .withdrawMakerProfit()
      .send({ ...this.config })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.APPROVE_SPEND, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.APPROVE_SPEND, error, callback));
  }

  withdrawBaseFees(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .withdrawBaseFees()
      .send({ ...this.config })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.APPROVE_SPEND, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.APPROVE_SPEND, error, callback));
  }

  injectProfit(profit: string, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .injectProfit(profit)
      .send({ ...this.config })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.APPROVE_SPEND, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.APPROVE_SPEND, error, callback));
  }

  startVoting(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .startVoting()
      .send({ ...this.config })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.APPROVE_SPEND, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.APPROVE_SPEND, error, callback));
  }

  validateVotingProgress(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .validateVotingProgress()
      .send({ ...this.config })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.APPROVE_SPEND, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.APPROVE_SPEND, error, callback));
  }

  voteForClose(callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .voteForClose()
      .send({ ...this.config, value: "10000000000000000" })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.APPROVE_SPEND, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.APPROVE_SPEND, error, callback));
  }

  invest(amount: string, callback: (error: AppErrorCode | null) => void) {
    console.log(amount);
    return this.contract
      .invest(amount)
      .send({ ...this.config, value: "10000000000000000" })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.APPROVE_SPEND, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.APPROVE_SPEND, error, callback));
  }

  withdrawBalance(amount: string, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .withdrawBalance(amount)
      .send({ ...this.config, value: "10000000000000000" })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.APPROVE_SPEND, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.APPROVE_SPEND, error, callback));
  }

  withdrawProfit(amount: string, callback: (error: AppErrorCode | null) => void) {
    return this.contract
      .withdrawProfit(amount)
      .send({ ...this.config, value: "10000000000000000" })
      .on("receipt", (receipt: any) => {
        this.defaultReceipt(AppErrorCode.APPROVE_SPEND, receipt, callback);
      })
      .on("error", (error: any) => this.errorCallback(AppErrorCode.APPROVE_SPEND, error, callback));
  }
}
