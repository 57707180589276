import React from "react";
import { toast } from "react-toastify";
import { Area, AreaChart, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import Web3 from "web3";
import { CrowdFunding } from "../../../core/contracts/crowdFunding";
import { Token } from "../../../core/contracts/token";
import { ApiHelpers } from "../../../core/helpers/api";
import { UtilsHelpers } from "../../../core/helpers/utils";
import { APIPoolDataInterface, APIPoolInjectionTransaction, APIPoolLogInterface, PoolDataInterface } from "../../../core/types";
import { BlockChainState } from "../../../storage/state/blockChain/state";
import { InvestorActionsComponent } from "../items/investorActions";

import TokenAbi from "../../../assets/contracts/Token.json";
import PoolContractAbi from "../../../assets/contracts/CrowdFundingController.json";

import { BlockChainHelpers } from "../../../core/helpers/chain";

import "./dynamic.css";
import { ApplicationState } from "../../../storage/state/app/state";

interface DynamicPoolProps {
  blockChain: BlockChainState;
  appState: ApplicationState;
  onLoadBlockChain: () => void;
  onLoadCustomerData: () => void;
  setAvailableNetworks: (networks: string[]) => void;
  setProviderError: (error: boolean) => void;
  setNetworksError: (error: boolean) => void;
}

interface DynamicPoolState {
  poolData: APIPoolDataInterface | null;
  poolContractData: PoolDataInterface | null;
  logsData: APIPoolLogInterface[] | null;
  totalRewardsData: any[];
  tokenInstance: any;
  poolInstance: any;
  injectionTransactions: APIPoolInjectionTransaction[];
  chainData: any;
}

export class DynamicPool extends React.Component<DynamicPoolProps, DynamicPoolState> {
  constructor(props: DynamicPoolProps) {
    super(props);

    this.state = {
      poolData: null,
      logsData: null,
      poolContractData: null,
      totalRewardsData: [],
      tokenInstance: null,
      poolInstance: null,
      injectionTransactions: [],
      chainData: null,
    };
  }

  componentDidMount(): void {
    this.mainPoolLoading();
  }

  mainPoolLoading() {
    const poolId = window.location.pathname.split("/")[2];

    if (poolId) {
      ApiHelpers.getPoolById(poolId).then((data) => {
        if (data && !data.error && !!data.data) {
          this.props.setAvailableNetworks(["0x" + Number(data.data?.pool?.network).toString(16)]);
          this.setState(
            {
              poolData: data.data.pool,
              logsData: data.data.logs,
            },
            () => {
              if (data.data && data.data.pool) {
                this.validateBlockchain();
                this.generateCharts();
                this.contractInfoLoading();
                this.loadLastInjections();
              }
            }
          );
        } else toast.error("[POOL LOADER] Invalid pool API data.");
      });
    } else toast.error("[POOL LOADER] Invalid pool ID");
  }

  async contractInfoLoading() {
    if (this.state.poolData?.address && this.state.poolData.funding.currency) {
      let tokenInstance = null;
      let poolInstance = null;
      let poolContractData = null;

      if (this.props.blockChain.controller && this.props.blockChain.controller._web3 && this.props.blockChain.controller?.selectedAccount) {
        tokenInstance = new Token(
          new this.props.blockChain.controller._web3.eth.Contract(TokenAbi.abi as any, this.state.poolData.funding.currency),
          this.props.blockChain.controller?._web3,
          this.props.blockChain.controller?.selectedAccount
        );

        poolInstance = new CrowdFunding(
          new this.props.blockChain.controller._web3.eth.Contract(PoolContractAbi.abi as any, this.state.poolData.address),
          this.props.blockChain.controller?._web3,
          this.props.blockChain.controller?.selectedAccount
        );

        poolContractData = await poolInstance.getContractInfo(this.props.blockChain.controller.selectedAccount);
      }

      this.setState({ tokenInstance, poolInstance, poolContractData });
    }
  }

  async loadLastInjections() {
    if (this.state.poolData) {
      ApiHelpers.getLastPoolInjections(this.state.poolData.address, this.state.poolData.network).then((injectionLogs) => {
        if (injectionLogs.error) {
          toast.error("[LOADER ERROR] Error loading the last injection transactions.");
          console.log(injectionLogs.error);
        } else if (Array.isArray(injectionLogs.data)) {
          this.setState({ injectionTransactions: injectionLogs.data });
        }
      });
    }
  }

  generateCharts() {
    if (this.state.logsData) {
      console.log(this.state.logsData);
      this.setState({
        totalRewardsData: this.parseTotalRewardsDataChart(this.state.logsData),
      });
    }
  }

  parseTotalRewardsDataChart(logs: APIPoolLogInterface[]) {
    if (this.state.poolData) {
      let reversedLogs = [...logs].splice(0, 100).reverse();
      console.log(reversedLogs);
      let plusDates: any = {};

      for (let i = 0; i < reversedLogs.length; i++) {
        const date = UtilsHelpers.toUTCTime(new Date(reversedLogs[i].date));
        const dateCode = date.getDate() + "-" + date.getMonth() + "-" + date.getFullYear();
        const amount = Number(Web3.utils.fromWei(reversedLogs[i].totalProfit));
        const availableRewards = Number(Web3.utils.fromWei(reversedLogs[i].injectedRewards));
        plusDates[dateCode] = { rewards: amount, stakedRewards: availableRewards, dateTime: date.getTime() };
        if (Object.keys(plusDates).length > 30) break;
      }
      console.log(plusDates);
      return Object.keys(plusDates).map((key) => ({ ...plusDates[key], date: key }));
    } else return [];
  }

  validateBlockchain() {
    if (this.state.poolData) {
      const provider = BlockChainHelpers.getProvider();

      if (provider && provider.eth) {
        provider.eth.getChainId().then((chainId) => {
          const isConnected = BlockChainHelpers.isConnected();
          const searchedBlockchain = BlockChainHelpers.findChainDataById("0x" + chainId.toString(16));
          const isValidBlockChain = searchedBlockchain?.chainId === "0x" + Number(this.state.poolData?.network).toString(16);

          if (isValidBlockChain && isConnected) {
            this.props.setNetworksError(false);
            this.props.setProviderError(false);
            this.props.onLoadBlockChain();
            this.props.onLoadCustomerData();
          } else this.props.setNetworksError(true);
        });
      } else this.props.setProviderError(true);
    }
  }

  connectToBlockchain() {
    if (this.state.poolData) {
      BlockChainHelpers.validateConnection().then((connected) =>
        connected
          ? BlockChainHelpers.changeEthereumChain("0x" + Number(this.state.poolData?.network).toString(16)).then(() => {
              this.props.onLoadBlockChain();
              this.props.onLoadCustomerData();
            })
          : null
      );
    }
  }

  componentDidUpdate(prevProps: Readonly<DynamicPoolProps>, prevState: Readonly<DynamicPoolState>, snapshot?: any): void {
    if (!prevProps.blockChain.controller?.selectedAccount && this.props.blockChain.controller?.selectedAccount) {
      this.mainPoolLoading();
    }

    if (prevProps.appState.networkError && this.props.blockChain.controller?.selectedAccount) {
      this.props.setNetworksError(false);
      this.contractInfoLoading();
    }

    if (prevProps.appState.providerError && this.props.blockChain.controller?.selectedAccount) {
      this.props.setProviderError(false);
      this.contractInfoLoading();
    }
  }

  render() {
    const currentAmount = Number(Web3.utils.fromWei(this.state.poolData?.funding.currentAmount || "0"));
    const goalAmount = Number(Web3.utils.fromWei(this.state.poolData?.funding.goal || "0"));
    const progressPercentage = (currentAmount * 100) / goalAmount;

    const todayTotalRewards = this.state.totalRewardsData[this.state.totalRewardsData.length - 1]
      ? this.state.totalRewardsData[this.state.totalRewardsData.length - 1].rewards
      : 0;

    const yesterdayTotalRewards = this.state.totalRewardsData[this.state.totalRewardsData.length - 2]
      ? this.state.totalRewardsData[this.state.totalRewardsData.length - 2].rewards
      : 0;

    const anualProfit = (
      (((this.state.poolData?.poolData.weeklyRewards || 0) / 7) * 365 * 100) /
      Number(Web3.utils.fromWei(this.state.poolData?.funding.goal || "0"))
    ).toFixed(2);

    const selectedChain = BlockChainHelpers.findChainDataById("0x" + Number(this.state.poolData?.network).toString(16));

    return (
      <div className="ct-dynamic-pool-container">
        <h4>
          {this.state.poolData?.poolData?.title || ""}{" "}
          {this.state.poolData?.poolData?.test ? <strong className="ct-testnet-pool">(Testnet pool)</strong> : ""}
        </h4>
        <p>
          <small>{this.state.poolData?.poolData.subtitle}</small>
        </p>
        <div dangerouslySetInnerHTML={{ __html: this.state.poolData?.poolData.description || "" }}></div>

        {Array.isArray(this.state.poolData?.poolData.benefits) && (this.state.poolData?.poolData?.benefits?.length || 0) > 0 ? (
          <div className="ct-pool-benefits">
            <h4>Benefits</h4>
            <ul>
              {this.state.poolData?.poolData.benefits.map((benefit) => {
                return <li>{benefit}</li>;
              })}
            </ul>
          </div>
        ) : (
          ""
        )}

        <div className="ct-progress-bar-container">
          <div className="ct-progress-bar" style={{ width: progressPercentage.toFixed(2) + "%" }}></div>
          <span>
            {currentAmount.toFixed(2)} / {goalAmount.toFixed(2)} - {progressPercentage.toFixed(2)}%
          </span>
        </div>

        {this.state.poolContractData && this.state.poolData && this.state.poolInstance && this.state.tokenInstance ? (
          <div className="ct-investor-container ct-mt-20">
            <h4>Investor</h4>
            <small>Invest like a pro using our community leveraged systems</small>
            <ul>
              <li>
                <strong>Pool participation:</strong> Total funds invested on the pool.
              </li>
              <li>
                <strong>Pool percentage:</strong> If you are an investor you will see the pool participation percentage based on your investment and
                the pool goal.
              </li>
              <li>
                <strong>Available profit:</strong> It is your available profit earned based on the pool rewards. Normaly the bot will inject new
                profit at the end of the week.
              </li>
              <li>
                <strong>Collected profit:</strong> It is the total extracted profit from the pool rewards.
              </li>
            </ul>
            <div className="ct-investor-data">
              <ul>
                <li>
                  <strong>Pool participation </strong> {Number(Web3.utils.fromWei(this.state.poolContractData.user?.data?.balance)).toFixed(2)}{" "}
                  {this.state.poolData.poolData.currency}
                </li>

                <li>
                  <strong>Pool percentage </strong>
                  {(this.state.poolContractData.user.data.percentage / 100000).toFixed(2)} %
                </li>

                <li>
                  <strong>Available profit </strong>
                  {Number(Web3.utils.fromWei(this.state.poolContractData.user.availableProfit)).toFixed(2)} {this.state.poolData.poolData.currency}
                </li>

                <li>
                  <strong>Collected profit </strong>
                  {Number(Web3.utils.fromWei(this.state.poolContractData.user.data.profitWithdraw)).toFixed(2)}{" "}
                  {this.state.poolData.poolData.currency}
                </li>
              </ul>
            </div>
            <h4 className="ct-mt-20">Investor actions</h4>
            <small>Withdraw, vote and inject funds on the pool</small>
            <ul>
              <li>
                <strong>Invest:</strong> Inject funds on the pool buying a percentage based on the pool goals. It is only available when the pool is
                starting.
              </li>
              <li>
                <strong>Withdraw funding:</strong> Withdraw injected funds. You only can do it when the pool is starting or when the pool is closed,
                if the pool start you wouldn't be able to withdraw your funds until it is closed.
              </li>
              <li>
                <strong>Withdraw profit:</strong> You can withdraw your profit when you want. It will be available when the pool has already started.
                You can withdraw your profit also if the pool is closed.
              </li>
              <li>
                <strong>Vote for close:</strong> It is only available when the users are voting to continue or close the pool, if the votes reached
                more than 50% the pool will be closed and all users will be able to withdraw their funds.
              </li>
            </ul>
            <InvestorActionsComponent
              poolInstance={this.state.poolInstance}
              tokenInstance={this.state.tokenInstance}
              onReloadPool={() => this.mainPoolLoading()}
              onLoadBlockChain={() => this.props.onLoadBlockChain()}
              onLoadCustomerData={() => this.props.onLoadCustomerData()}
              blockChain={this.props.blockChain}
              pool={this.state.poolContractData}
              poolData={this.state.poolData}
            />
          </div>
        ) : this.state.poolData?.network ? (
          <div className="ct-connect-to-network">
            <h4>Invalid blockchain information</h4>
            <p>Connect your provider to the correct network</p>
            <button className="ct-main-button " onClick={() => this.connectToBlockchain()}>
              Connect ({UtilsHelpers.getChainNameById(this.state.poolData?.network)})
            </button>
          </div>
        ) : (
          ""
        )}

        <h4>Statistics</h4>
        <div className="ct-charts">
          <div className="ct-rewards">
            <h4>Total - Available and collected Rewards</h4>
            <small>You can see how many rewards we distribute over the time to all pool users</small>
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart width={500} height={300} data={this.state.totalRewardsData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis style={{ fontSize: 10 }} dataKey="date" />
                <Tooltip />
                <Area type="monotone" dataKey="rewards" name="Collected rewards" stroke="rgba(0, 172, 238, 1)" fill="rgba(0, 172, 238, 0.7)" />
                <Area
                  type="monotone"
                  dataKey="stakedRewards"
                  name="Available rewards"
                  stroke="rgba(46, 204, 113, 1)"
                  fill="rgba(46, 204, 113, 0.7)"
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
          <p className="ct-days-to-fill">
            {this.state.totalRewardsData.length > 0 ? (
              <small>
                We have collected {todayTotalRewards.toFixed(3)} {this.state.poolData?.poolData.currency} since we started
              </small>
            ) : (
              ""
            )}

            {this.state.totalRewardsData.length > 0 ? (
              <small>
                Today we collected {(todayTotalRewards - yesterdayTotalRewards).toFixed(3)} {this.state.poolData?.poolData.currency}
              </small>
            ) : (
              ""
            )}
            {this.state.totalRewardsData.length > 0 ? (
              <small>
                Estimated APR using the last rewards {anualProfit} % (If we collect the same rewards the next week and for the whole year)
              </small>
            ) : (
              ""
            )}
          </p>
        </div>

        {this.state.injectionTransactions.length > 0 ? (
          <>
            <h4 className="ct-mt-20">Last transactions</h4>
            <div className="ct-dynamic-injected-transactions ct-mt-20">
              {this.state.injectionTransactions.map((tx) => {
                const date = new Date(tx.date * 1000);
                return (
                  <div key={tx.hash} className="ct-injection-tx">
                    <div className="ct-type ct-injection-type">
                      <span>Injection</span>
                    </div>
                    <div className="ct-data">
                      <span>Amount: {tx.amount + " " + this.state.poolData?.poolData.currency}</span> <span>Time: {date.toLocaleTimeString()}</span>
                      <span>Date: {date.toLocaleDateString()}</span>
                    </div>
                    {selectedChain && tx.hash && selectedChain?.blockExplorerUrls ? (
                      <div className="ct-actions">
                        <a
                          href={selectedChain?.blockExplorerUrls[0] ? selectedChain?.blockExplorerUrls[0] + "/tx/" + tx.hash : ""}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <span className="fas fa-globe"></span>
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          ""
        )}
      </div>
    );
  }
}
