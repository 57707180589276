import React, { useState } from "react";
import { BlockChainState } from "../../storage/state/blockChain/state";
import { ApplicationState } from "../../storage/state/app/state";
import { TokenController } from "../../core/modules/token";

import ItemList from "../organisms/items/dashboard_item";
import { CrowdFunding } from "../../core/contracts/crowdFunding";
import PoolContractAbi from "../../assets/contracts/CrowdFundingController.json";
import TokenContractAbi from "../../assets/contracts/Token.json";
import { Token } from "../../core/contracts/token";
import { PieChart, Pie, Legend, Tooltip, Label, Treemap, ResponsiveContainer } from "recharts";
import Web3 from "web3";
import { appConfig, AppErrorCode } from "../../core/app";
import { Link } from "react-router-dom";
import { PoolsList, UsersPoolsList } from "../organisms/pool/list";
import { BlockChainHelpers } from "../../core/helpers/chain";
import { ApiHelpers } from "../../core/helpers/api";
import { APIPoolDataInterface, APIUserPoolInfo } from "../../core/types";
import { toast } from "react-toastify";
import { UtilsHelpers } from "../../core/helpers/utils";

const TOKENS_KEY = "BLOCKRAISINGKEY-88888448-MAIN-TOKENS-PRICE";

const COLORS = ["#8889DD", "#9597E4", "#8DC77B", "#A5D297", "#E2CF45", "#F8C12D"];

class CustomizedContent extends React.PureComponent<any> {
  render() {
    const { root, depth, x, y, width, height, index, payload, colors, rank, name, percentage } = this.props;

    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          style={{
            fill: depth < 2 ? colors[Math.floor((index / root.children.length) * 6)] : "#ffffff00",
            stroke: "#fff",
            strokeWidth: 2 / (depth + 1e-10),
            strokeOpacity: 1 / (depth + 1e-10),
          }}
        />
        {depth === 1 ? (
          <text x={x + width / 2} y={y + height / 2 + 7} textAnchor="middle" fill="#fff" fontSize={14}>
            {name}
          </text>
        ) : null}
        {depth === 1 ? (
          <text x={x + width / 2} y={y + height / 2 + 30} textAnchor="middle" fill="#fff" fontSize={14}>
            {percentage?.toFixed(1)}%
          </text>
        ) : null}
      </g>
    );
  }
}

interface DashboardComponentProps {
  appState: ApplicationState;
  blockChain: BlockChainState;
  onLoadBlockChain: () => void;
  onLoadCustomerData: (inTheEnd: boolean) => void;
  onToggleLoader: (froce: boolean) => void;
  onSetBlockChainError: (error: AppErrorCode) => void;
  setAvailableNetworks: (networks: string[]) => void;
  setProviderError: (error: boolean) => void;
  setNetworksError: (error: boolean) => void;
}

interface DashboardComponentState {
  userInfo: APIUserPoolInfo[];
  pools: APIPoolDataInterface[];
  treemapData: any[];
  tokenPrices: any;
}

const DASHBOAR_BLOCKCHAINS = ["0x38", "0x13881", "0x89"];

export class Dashboard extends React.PureComponent<DashboardComponentProps, DashboardComponentState> {
  constructor(props: DashboardComponentProps) {
    super(props);

    this.state = {
      userInfo: [],
      pools: [],
      treemapData: [],
      tokenPrices: null,
    };
  }

  async componentDidMount() {
    this.props.setAvailableNetworks(DASHBOAR_BLOCKCHAINS);
    this.preloadUserInformation();
    this.validateBlockchain();
  }

  preloadUserInformation() {
    if (this.props.blockChain.controller?.selectedAccount) {
      ApiHelpers.getUserInfo(this.props.blockChain.controller?.selectedAccount).then((userInfo) => {
        if (!userInfo.error && userInfo.data) {
          this.setState({ pools: userInfo.data.projects, userInfo: userInfo.data.userData }, () => {
            this.preloadTokensPrice(() => {
              this.generateTreemapData();
            });
          });
        } else toast.error("[DASHBOARD] Error loading API data");
      });
    }
  }

  preloadTokensPrice(callback: () => void) {
    const localData = localStorage.getItem(TOKENS_KEY);
    const parsedData = localData ? JSON.parse(localData) : null;

    if (parsedData) {
      this.setState({ tokenPrices: parsedData }, () => callback());
    } else {
      ApiHelpers.getMainTokensPrice().then((tokensPrice) => {
        localStorage.setItem(TOKENS_KEY, JSON.stringify(tokensPrice));
        this.setState({ tokenPrices: tokensPrice }, () => callback());
      });
    }
  }

  generateTreemapData() {
    if (Array.isArray(this.state.userInfo) && this.state.userInfo.length > 0 && this.state.tokenPrices && this.state.pools.length > 0) {
      try {
        let data = this.state.userInfo.map((info, index) => ({
          name: this.state.pools[index].poolData.title,
          size:
            Number(Web3.utils.fromWei(info.contractData.data.balance)) *
            UtilsHelpers.getTokenPricePerSymbol(this.state.pools[index].poolData.currency, this.state.tokenPrices),
          percentage: 0,
        }));

        const totalAmount = data.reduce((total, current) => (total += current.size), 0);

        data = data.map((info) => {
          return { ...info, percentage: (info.size * 100) / totalAmount };
        });

        this.setState({ treemapData: data });
      } catch (error) {
        console.log(error);
      }
    }
  }

  validateBlockchain() {
    const provider = BlockChainHelpers.getProvider();

    if (provider && provider.eth) {
      provider.eth.getChainId().then((chainId) => {
        const isConnected = BlockChainHelpers.isConnected();
        const isValidBlockChain = DASHBOAR_BLOCKCHAINS.includes("0x" + chainId.toString(16));

        if (isValidBlockChain && isConnected) {
          this.props.setNetworksError(false);
          this.props.setProviderError(false);
          this.props.onLoadBlockChain();
        } else this.props.setNetworksError(true);
      });
    } else this.props.setProviderError(true);
  }

  componentDidUpdate(prevProps: Readonly<DashboardComponentProps>, prevState: Readonly<DashboardComponentState>, snapshot?: any): void {
    if (!prevProps.blockChain.controller?.selectedAccount && this.props.blockChain.controller?.selectedAccount) {
      this.preloadUserInformation();
    }

    if (prevProps.appState.networkError && this.props.blockChain.controller?.selectedAccount) {
      this.props.setNetworksError(false);
      this.preloadUserInformation();
    }

    if (prevProps.appState.providerError && this.props.blockChain.controller?.selectedAccount) {
      this.props.setProviderError(false);
      this.preloadUserInformation();
    }
  }

  render() {
    return (
      <div className="ct-dashboard">
        <ul className="ct-circles-animation">
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
          <li></li>
        </ul>

        <div className="ct-dashboard-list ct-max-container ct-mt-50">
          <div className="ct-charts-container">
            <h4>Your statistics</h4>
            <h5 className="ct-mt-10">Investments distribution</h5>
            <p>
              <small>Every rectangle represent one investment</small>{" "}
            </p>
            {this.state.treemapData && Array.isArray(this.state.treemapData) && this.state.treemapData.length > 0 ? (
              <div className="ct-treemap-chart ct-mt-10">
                <ResponsiveContainer width="100%" height="100%">
                  <Treemap
                    isAnimationActive={false}
                    width={730}
                    height={250}
                    data={this.state.treemapData}
                    dataKey="size"
                    aspectRatio={4 / 3}
                    stroke="#fff"
                    fill="#8884d8"
                    content={<CustomizedContent colors={COLORS} />}
                  />
                </ResponsiveContainer>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="ct-header ct-max-container">
            <h4>Your investments</h4>
            <small>You will see your own investments.</small>
          </div>
          {this.state.pools?.length > 0 && this.state.userInfo?.length > 0 ? (
            <UsersPoolsList userInfo={this.state.userInfo} blockChain={this.props.blockChain} pools={this.state.pools} />
          ) : (
            <div className="ct-investment-recomendation">
              <h5>You dont have investments</h5>
              <p>Go to the pools page and invest on one pool to see your statistics</p>
              <button className="button-29 ct-mt-10">
                <Link to="/pools">Go to pools</Link>{" "}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}
