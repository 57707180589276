export interface CustomerToken {
  mainTokenBalance: number;
}

export interface ContractsAddress {}

export class AppData {
  constructor(public customerToken: CustomerToken, public contractsAddress: ContractsAddress) {}

  static buildFromTemplates(customerToken: CustomerToken, contractsAddress: ContractsAddress) {
    return new AppData(customerToken, contractsAddress);
  }
}

// Game

export interface ProjectDataInterface {
  title: string;
  image: string;
  description: string;
  profitInfo: {
    title: string;
    value: string;
    description: string;
  };
}

export interface PoolDataInterface {
  developerProfit: string;
  makerProfit: string;
  totalProfit: string;
  closeVotes: string;
  baseFee: string;
  totalFees: string;
  injectedRewards: string;
  totalUsers: string;
  funding: {
    state: number;
    developerEarn: number;
    makerEarnings: number;
    id: number;
    goal: string;
    duration: number;
    startFundingDate: number;
    finishFundingDate: number;
    votingDuration: number;
    finishVotingDate: number;
    currentAmount: string;
    currency: string;
    makerAccount: string;
    developerAccount: string;
  };
  user: { hasVotes: boolean; availableProfit: string; data: { percentage: number; balance: string; profitWithdraw: string } };
}

export interface APIPoolDataInterface extends PoolDataInterface {
  _id: string;
  address: string;
  poolData: {
    image: string;
    title: string;
    description: string;
    smallDescription: string;
    subtitle: string;
    anualProfit: number;
    currency: string;
    weeklyRewards: number;
    collectedRewards: number;
    documentation: string;
    adminLink: string;
    test: boolean;
    benefits: string[];
  };
  network: string;
}

export interface APIPoolInjectionTransaction {
  hash: string;
  date: number;
  amount: number;
  network: string;
  pool: string;
}

export interface APIPoolLogInterface {
  network: string;
  address: string;
  developersProfit: string;
  makerProfit: string;
  totalProfit: string;
  totalFees: string;
  injectedRewards: string;
  totalUsers: string;
  currentAmount: string;
  date: number;
}

export interface APIUserPoolInfo {
  network: string;
  wallet: string;
  pool: string;
  contractData: {
    availableProfit: string;
    hasVotes: false;
    data: {
      percentage: string;
      balance: string;
      profitWithdraw: string;
    };
  };
}
