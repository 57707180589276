import React, { useState } from "react";
import "./items.css";
import Web3 from "web3";

const SliderItem = (props: {
  title: string;
  image: string;
  progress: number;
  goal: number;
  description: string;
  currency: string;
  activeSlider: boolean;
}) => {
  function getPorcentage() {
    return ((props.progress / props.goal) * 100).toFixed(0);
  }

  return (
    <div className={"ct-slider-item" + (props.activeSlider ? " ct-active-slider" : "")}>
      <img className="imageContainer" src={props.image}></img>

      <div className="infoContainer">
        <div className="titleItem">{props.title}</div>
        <div className="overlay"></div>
        <p>{ props.description ? props.description.substring(0, props.description.length > 150 ? 150 : props.description.length - 1) : ""}...</p>
        <div className="progressBarContainer">
          <div className="progressBar" style={{ width: `${getPorcentage()}%` }}></div>
        </div>
      </div>
    </div>
  );
};

export default SliderItem;
