import { appConfig } from "../app";
import { BlockChainHelpers } from "./chain";

export class ApiHelpers {
  static configuration = {
    host: appConfig.API,
  };

  public static async get(url: string) {
    try {
      const json = await fetch(url);
      return await json.json();
    } catch (error) {
      return false;
    }
  }

  public static async post(url: string, data: any) {
    try {
      const json = await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
      });
      return await json.json();
    } catch (error) {
      return false;
    }
  }

  public static async uploadImages(formData: FormData) {
    try {
      const json = await fetch(this.configuration.host + "/upload-images", {
        method: "POST",
        body: formData,
      });
      return await json.json();
    } catch (error) {
      return false;
    }
  }

  public static async getMainTokensPrice() {
    return await ApiHelpers.get(this.configuration.host + "/stats-controller/getLastMainTokenPrices");
  }

  public static async getPools() {
    return await ApiHelpers.get(this.configuration.host + "/pools-controller/all");
  }

  public static async getUserInfo(address: string) {
    return await ApiHelpers.get(this.configuration.host + "/users-controller/user-info/" + address);
  }

  public static async getPool(address: string) {
    return await ApiHelpers.get(this.configuration.host + "/pools-controller/pool/" + address);
  }

  public static async getPoolById(id: string) {
    return await ApiHelpers.get(this.configuration.host + "/pools-controller/pool-by-id/" + id);
  }

  public static async getLastPoolInjections(address: string, network: string, days: number = 30) {
    return await ApiHelpers.get(this.configuration.host + "/pools-controller/pool-logs-injections/" + address + "/" + network + "/" + days);
  }

  public static async getStats() {
    return await ApiHelpers.get(this.configuration.host + "/stats-controller/getData");
  }
}
