import Web3 from "web3";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { appConfig, AppErrorCode } from "../../../core/app";
import { Token } from "../../../core/contracts/token";
import { APIPoolDataInterface, PoolDataInterface } from "../../../core/types";
import { BlockChainState } from "../../../storage/state/blockChain/state";
import { CrowdFunding } from "../../../core/contracts/crowdFunding";

import TokenAbi from "../../../assets/contracts/Token.json";
import PoolContractAbi from "../../../assets/contracts/CrowdFundingController.json";
import { UtilsHelpers } from "../../../core/helpers/utils";
import { BlockChainHelpers } from "../../../core/helpers/chain";

import "./investorActions.css";

interface InvestorActionsProps {
  poolData: APIPoolDataInterface;
  pool: PoolDataInterface;
  blockChain: BlockChainState;
  tokenInstance: Token;
  poolInstance?: CrowdFunding;
  onReloadPool: () => void;
  onLoadBlockChain: () => void;
  onLoadCustomerData: () => void;
}
interface InvestorActionsState {
  investment: string;
  withdrawBalance: string;
  withdrawProfit: string;
  injectProfit: string;
  approvedTokens: string;
}

export class InvestorActionsComponent extends React.Component<InvestorActionsProps, InvestorActionsState> {
  constructor(props: InvestorActionsProps) {
    super(props);

    this.state = {
      investment: "0",
      withdrawBalance: "0",
      withdrawProfit: "0",
      injectProfit: "0",
      approvedTokens: "0",
    };
  }

  componentDidMount(): void {
    this.reloadNormalizedState();
  }

  async reloadNormalizedState() {
    let approvedTokens = "0";
    if (this.props.tokenInstance) {
      if (this.props.blockChain.controller?.selectedAccount) {
        approvedTokens = Web3.utils.fromWei(
          await this.props.tokenInstance.allowance(this.props.blockChain.controller?.selectedAccount, this.props.poolData.address)
        );
      }
    }

    this.setState({ approvedTokens });
  }

  onInvest() {
    if (this.props.poolInstance) {
      this.props.poolInstance.invest(Web3.utils.toWei(this.state.investment), (error: AppErrorCode | null) => {
        if (error) toast.error("[TOKEN] Invalid investment");
        else toast.success("[TOKEN] You are on the pool, thanks for invest with us.");
        this.props.onReloadPool();
        this.reloadNormalizedState();
      });
    }
  }

  onWithdrawBalance() {
    if (this.props.poolInstance) {
      this.props.poolInstance.withdrawBalance(Web3.utils.toWei(this.state.withdrawBalance), (error: AppErrorCode | null) => {
        if (error) toast.error("[TOKEN] Invalid withdraw");
        else toast.success("[TOKEN] Bye bye.");
        this.props.onReloadPool();
        this.reloadNormalizedState();
      });
    }
  }

  onWithdrawProfit() {
    if (this.props.poolInstance) {
      this.props.poolInstance.withdrawProfit(Web3.utils.toWei(this.state.withdrawProfit), (error: AppErrorCode | null) => {
        if (error) toast.error("[TOKEN] Invalid withdraw");
        else toast.success("[TOKEN] Thanks for participate on the pool.");
        this.props.onReloadPool();
        this.reloadNormalizedState();
      });
    }
  }

  onWithdrawDevelopersProfit() {
    if (this.props.poolInstance) {
      this.props.poolInstance.withdrawDevelopersProfit((error: AppErrorCode | null) => {
        if (error) toast.error("[TOKEN] Invalid withdraw");
        else toast.success("[TOKEN] Withdraw more the next time.");
        this.props.onReloadPool();
        this.reloadNormalizedState();
      });
    }
  }

  onWithdrawPoolProfit() {
    if (this.props.poolInstance) {
      this.props.poolInstance.withdrawBaseFees((error: AppErrorCode | null) => {
        if (error) toast.error("[TOKEN] Invalid withdraw");
        else toast.success("[TOKEN] Withdraw more the next time.");
        this.props.onReloadPool();
        this.reloadNormalizedState();
      });
    }
  }

  onWithdrawMakersProfit() {
    if (this.props.poolInstance) {
      this.props.poolInstance.withdrawMakerProfit((error: AppErrorCode | null) => {
        if (error) toast.error("[TOKEN] Invalid withdraw");
        else toast.success("[TOKEN] Withdraw more the next time.");
        this.props.onReloadPool();
        this.reloadNormalizedState();
      });
    }
  }

  onVoteForClose() {
    if (this.props.poolInstance) {
      this.props.poolInstance.voteForClose((error: AppErrorCode | null) => {
        if (error) toast.error("[TOKEN] Invalid votes");
        else toast.success("[TOKEN] Thanks for participate on the pool.");
        this.props.onReloadPool();
        this.reloadNormalizedState();
      });
    }
  }

  onInjectProfit() {
    if (this.props.poolInstance) {
      this.props.poolInstance.injectProfit(Web3.utils.toWei(this.state.injectProfit), (error: AppErrorCode | null) => {
        if (error) toast.error("[TOKEN] Invalid injection");
        else toast.success("[TOKEN] Successfull injection.");
        this.reloadNormalizedState();
        this.props.onReloadPool();
      });
    }
  }

  onApprove(amount: string) {
    if (this.props.tokenInstance) {
      this.props.tokenInstance.approve(this.props.poolData.address, Number(amount) * 1.1, (error: AppErrorCode | null) => {
        if (error) toast.error("[TOKEN] Invalid approve");
        else toast.success("[TOKEN] You tokens are approved");
        this.reloadNormalizedState();
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<InvestorActionsProps>, prevState: Readonly<InvestorActionsState>, snapshot?: any): void {
    if (
      (!prevProps.blockChain.controller?.selectedAccount && this.props.blockChain.controller?.selectedAccount) ||
      (!prevProps.tokenInstance && this.props.tokenInstance)
    ) {
      this.reloadNormalizedState();
    }
  }

  render() {
    const isDeveloper = appConfig.devWallet.includes(this.props.blockChain.controller?.selectedAccount?.toLocaleLowerCase() || "");

    return (
      <div className="ct-investor-actions ct-actions">
        {this.props.blockChain.controller?.selectedAccount ? (
          <React.Fragment>
            <div className={"ct-action ct-invest" + (Number(this.props.pool.funding.state) !== 1 ? " ct-deactivated-action" : "")}>
              {Number(this.props.pool.funding.state) !== 1 ? (
                <div className="ct-deactivated-shadow">
                  <h4>Invalid action</h4>
                </div>
              ) : (
                ""
              )}
              <h4>Invest</h4>
              <div className="ct-input-container">
                <input
                  type="number"
                  placeholder={"1 MTRG"}
                  value={this.state.investment}
                  onChange={(e) => this.setState({ investment: e.target.value })}
                />
                <button
                  onClick={() =>
                    this.setState({
                      investment: (
                        Number(Web3.utils.fromWei(this.props.pool.funding.goal)) - Number(Web3.utils.fromWei(this.props.pool.funding.currentAmount))
                      ).toString(),
                    })
                  }
                >
                  Max
                </button>
              </div>
              {Number(this.state.approvedTokens) > Number(this.state.investment) ? (
                <button onClick={() => this.onInvest()} className="btn-2">
                  Invest ({this.state.investment} {UtilsHelpers.getTokenSymbolByAdress(this.props.pool.funding.currency)})
                </button>
              ) : (
                <button onClick={() => this.onApprove(this.state.investment)} className="btn-2">
                  Approve ({this.state.investment} {UtilsHelpers.getTokenSymbolByAdress(this.props.pool.funding.currency)})
                </button>
              )}

              <p>
                <small>You only can invest when the pool is open and is in the funding process.</small>
              </p>
              <ul>
                <li>
                  Investment fee: <strong>0%</strong>
                </li>
              </ul>
            </div>

            <div
              className={
                "ct-action ct-invest" +
                (Number(this.props.pool.funding.state) === 2 || Number(this.props.pool.funding.state) === 4 ? " ct-deactivated-action" : "")
              }
            >
              {Number(this.props.pool.funding.state) === 2 || Number(this.props.pool.funding.state) === 4 ? (
                <div className="ct-deactivated-shadow">
                  <h4>Invalid action</h4>
                </div>
              ) : (
                ""
              )}
              <h4>Withdraw funding</h4>
              <div className="ct-input-container">
                <input
                  type="number"
                  placeholder={"1 MTRG"}
                  value={this.state.withdrawBalance}
                  onChange={(e) => this.setState({ withdrawBalance: e.target.value })}
                />
                <button onClick={() => this.setState({ withdrawBalance: Web3.utils.fromWei(this.props.pool.user.data.balance) })}>Max</button>
              </div>

              <button onClick={() => this.onWithdrawBalance()} className="btn-2">
                Withdraw ({this.state.withdrawBalance} {UtilsHelpers.getTokenSymbolByAdress(this.props.pool.funding.currency)})
              </button>
              <p>
                <small>Withdraw when the pool is open for funding or when the pool is closed.</small>
              </p>
              <ul>
                <li>
                  Withdraw fee: <strong>5%</strong>
                </li>
              </ul>
            </div>

            <div
              className={
                "ct-action ct-invest" +
                (Number(this.props.pool.funding.state) === 1 || Number(this.props.pool.funding.state) === 3 ? " ct-deactivated-action" : "")
              }
            >
              {Number(this.props.pool.funding.state) === 1 || Number(this.props.pool.funding.state) === 3 ? (
                <div className="ct-deactivated-shadow">
                  <h4>Invalid action</h4>
                </div>
              ) : (
                ""
              )}

              <h4>Withdraw profit</h4>
              <div className="ct-input-container">
                <input
                  type="number"
                  placeholder={"1 MTRG"}
                  value={this.state.withdrawProfit}
                  onChange={(e) => this.setState({ withdrawProfit: e.target.value })}
                />
                <button onClick={() => this.setState({ withdrawProfit: Web3.utils.fromWei(this.props.pool.user.availableProfit) })}>Max</button>
              </div>

              <button onClick={() => this.onWithdrawProfit()} className="btn-2">
                Withdraw ({this.state.withdrawProfit} {UtilsHelpers.getTokenSymbolByAdress(this.props.pool.funding.currency)})
              </button>

              <p>
                <small>Withdraw your profit when the pool is filled and running.</small>
              </p>

              <ul>
                <li>
                  Available profit:{" "}
                  <strong>
                    {Number(Web3.utils.fromWei(this.props.pool.user.availableProfit)).toFixed(2)} {this.props.poolData.poolData.currency}
                  </strong>
                </li>
                <li>
                  Withdraw fee: <strong>0%</strong>
                </li>
              </ul>
            </div>

            <div className={"ct-action ct-invest" + (Number(this.props.pool.funding.state) !== 4 ? " ct-deactivated-action" : "")}>
              {Number(this.props.pool.funding.state) !== 4 ? (
                <div className="ct-deactivated-shadow">
                  <h4>Invalid action</h4>
                </div>
              ) : (
                ""
              )}
              <h4>Vote for close</h4>
              <button onClick={() => this.onVoteForClose()} className="btn-2">
                Vot for close ({Number(this.props.pool.user.data.percentage) / 100000}%)
              </button>
              <p>
                <small>
                  You will vote to close the pool with your participation. If the pool reach more than 50% votes the pool will be closed and all users
                  will be able to withdraw the initial funds.
                </small>
              </p>
              <ul>
                <li>
                  Total votes for close: <strong>{Number(this.props.pool.closeVotes) / 100000}%</strong>
                </li>
              </ul>
            </div>

            {isDeveloper ? (
              <React.Fragment>
                <div className={"ct-action ct-invest"}>
                  <h4>Inject profit</h4>
                  <div className="ct-input-container">
                    <input
                      type="number"
                      placeholder={"1 MTRG"}
                      value={this.state.injectProfit}
                      onChange={(e) => this.setState({ injectProfit: e.target.value })}
                    />
                  </div>
                  {Number(this.state.approvedTokens) > Number(this.state.injectProfit) ? (
                    <button onClick={() => this.onInjectProfit()} className="btn-2">
                      Inject ({this.state.injectProfit} {UtilsHelpers.getTokenSymbolByAdress(this.props.pool.funding.currency)})
                    </button>
                  ) : (
                    <button onClick={() => this.onApprove(this.state.injectProfit)} className="btn-2">
                      Approve ({this.state.injectProfit} {UtilsHelpers.getTokenSymbolByAdress(this.props.pool.funding.currency)})
                    </button>
                  )}
                  <p>
                    <small>The developers will inject profit at the end of the weeks</small>{" "}
                  </p>
                </div>
                <div className={"ct-action ct-invest"}>
                  <h4>Withdraw Developers profit</h4>
                  <button onClick={() => this.onWithdrawDevelopersProfit()} className="btn-2">
                    Withdraw ({Number(Web3.utils.fromWei(this.props.pool.developerProfit?.toString() || "0")).toFixed(3)}{" "}
                    {UtilsHelpers.getTokenSymbolByAdress(this.props.pool.funding.currency)})
                  </button>
                  <p>
                    <small>The developers can withdraw all profit from the fees</small>{" "}
                  </p>
                  <ul>
                    <li>
                      Total profit: {Number(Web3.utils.fromWei(this.props.pool.developerProfit?.toString() || "0")).toFixed(3)}{" "}
                      {UtilsHelpers.getTokenSymbolByAdress(this.props.pool.funding.currency)}
                    </li>
                  </ul>
                </div>
                <div className={"ct-action ct-invest"}>
                  <h4>Withdraw pool fees</h4>
                  <button onClick={() => this.onWithdrawPoolProfit()} className="btn-2">
                    Withdraw ({Number(Web3.utils.fromWei(this.props.pool.totalFees?.toString() || "0")).toFixed(3)}{" "}
                    {UtilsHelpers.getChainSymbolById(this.props.poolData.network)} )
                  </button>
                  <p>
                    {" "}
                    <small>The developers can use all the fees to support the project sustainability and maintain the development</small>{" "}
                  </p>
                  <ul>
                    <li>
                      Total profit: {Number(Web3.utils.fromWei(this.props.pool.totalFees?.toString() || "0")).toFixed(3)}{" "}
                      {UtilsHelpers.getChainSymbolById(this.props.poolData.network)}
                    </li>
                  </ul>
                </div>
                <div className={"ct-action ct-invest"}>
                  <h4>Withdraw Maker profit</h4>
                  <button onClick={() => this.onWithdrawMakersProfit()} className="btn-2">
                    Withdraw ({Number(Web3.utils.fromWei(this.props.pool.makerProfit?.toString() || "0")).toFixed(3)}{" "}
                    {UtilsHelpers.getTokenSymbolByAdress(this.props.pool.funding.currency)})
                  </button>
                  <p>
                    <small>The pool creator can withdraw all profit from the fees</small>{" "}
                  </p>
                  <ul>
                    <li>
                      Total profit: {Number(Web3.utils.fromWei(this.props.pool.makerProfit?.toString() || "0")).toFixed(3)}{" "}
                      {UtilsHelpers.getTokenSymbolByAdress(this.props.pool.funding.currency)}
                    </li>
                  </ul>
                </div>
              </React.Fragment>
            ) : (
              ""
            )}
          </React.Fragment>
        ) : (
          <div className="ct-connect-to-network">
            <h4>Unnable to load pool information</h4>
            <p>Connect your provider network to load everything correctly</p>
            <button className="ct-main-button" onClick={() => {}}>
              Connect ({UtilsHelpers.getChainNameById(this.props.poolData.network)})
            </button>
          </div>
        )}
      </div>
    );
  }
}
