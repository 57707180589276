import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { PieChart, Pie, Legend, Tooltip, ResponsiveContainer, Label } from "recharts";


interface ItemListProps {
  title?: any;
  data?: any;
  internal?: any;
  customActions?: any;
}

export default function ItemList(props: ItemListProps) {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };
  const data02 = [
    { name: "Nodes", value: 2400 },
    { name: "Bots", value: 4567 },
  ];
  return (
    <div>
      <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
        <AccordionSummary
          className="ct-pool-header"
          expandIcon={
            props.customActions ? (
              <div className="ct-accordion-actions">
                {props.customActions}
                <ExpandMoreIcon />
              </div>
            ) : (
              <ExpandMoreIcon />
            )
          }
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          {props.title ? props.title : <Typography sx={{ width: "33%", flexShrink: 0 }}>Node</Typography>}
          {props.data ? props.data : <Typography sx={{ color: "text.secondary" }}>Year APR</Typography>}
        </AccordionSummary>
        <AccordionDetails>
          {props.internal ? (
            props.internal
          ) : (
            <PieChart width={200} height={200}>
              <Pie cx="50%" cy="50%" dataKey="value" data={data02} innerRadius={60} outerRadius={90} fill="rgb(77, 148, 241)">
                <Label value="Total Invested: " position="center" />
              </Pie>
              <Tooltip />
            </PieChart>
          )}
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
