import React, { Component } from "react";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import Image1 from "../../../assets/images/description/Image1.svg";
import Image2 from "../../../assets/images/description/Image2.svg";
import Image3 from "../../../assets/images/description/Image3.svg";
import Image5 from "../../../assets/images/description/Image5.svg";
import BackgroundImage from "../../../assets/images/background/art_1.png";

import { ApiHelpers } from "../../../core/helpers/api";
import { Waves } from "../../molecules/waves/waves";
import "./statsBanner.css";
import { Waves2 } from "../../molecules/waves/waves2";
import { Waves3 } from "../../molecules/waves/waves3";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer } from "recharts";
import { AnimationOnScroll } from "react-animation-on-scroll";

interface StatsBannerProps {}
interface StatsBannerState {
  totalProjects: number;
  totalInvestment: number;
  totalUsers: number;
  totalProfit: number;
  chartData: any;
}

const chartData = [
  {
    name: "Page A",
    uv: 100,
  },
  {
    name: "Page B",
    uv: 150,
  },
  {
    name: "Page C",
    uv: 350,
  },
  {
    name: "Page D",
    uv: 150,
  },
  {
    name: "Page E",
    uv: 500,
  },
  {
    name: "Page F",
    uv: 650,
  },
  {
    name: "Page G",
    uv: 400,
  },
];

const MAX_DATA_INTERVAL = 20;

export default class StatsBanner extends Component<StatsBannerProps, StatsBannerState> {
  dataInterval: any;

  constructor(props: StatsBannerProps) {
    super(props);

    this.state = {
      totalProjects: 0,
      totalInvestment: 0,
      totalUsers: 0,
      totalProfit: 0,
      chartData: chartData,
    };
  }
  componentDidMount(): void {
    this.loadStats();
    this.createDataInterval();
  }

  counter(state: string, minimum: number, maximum: number, timeout: number) {
    for (let count = minimum; count <= maximum; count++) {
      setTimeout(() => {
        this.setState({ [state]: count } as any);
      }, timeout);
    }
  }

  async loadStats() {
    ApiHelpers.getStats()
      .then((data) => {
        console.log(data);
        if (data) {
          if (data.totalProjects) this.counter("totalProjects", 0, data.totalProjects, 1000);
          if (data.totalInvestment) this.counter("totalInvestment", 0, data.totalInvestment, 2000);
          if (data.totalUsers) this.counter("totalUsers", 0, data.totalUsers, 3000);
          if (data.totalProfit) this.counter("totalProfit", 0, data.totalProfit, 4000);
        } else throw new Error("Invalid API information");
      })
      .catch((error) => console.log(error));
  }

  componentWillUnmount(): void {
    if (this.dataInterval) clearInterval(this.dataInterval);
  }

  createDataInterval() {
    this.dataInterval = setInterval(() => {
      if (this.state.chartData.length < MAX_DATA_INTERVAL) {
        this.setState(
          {
            chartData: [
              ...this.state.chartData,
              {
                name: "Page " + Math.floor(Math.random() * 100),
                uv: this.getRandomInt(100, 800),
              },
            ],
          },
          () => console.log(this.state.chartData)
        );
      } else {
        clearInterval(this.dataInterval);
      }
    }, 2000);
  }

  getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  render() {
    return (
      <div className="ct-stats-parent-container">
        <div className="ct-shadow-container">
          <div className="ct-data-container ct-max-container">
            <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce>
              <div className="ct-stats-container">
                <span className="fas fa-network-wired"></span>
                <h1>Total Projects</h1>
                <h2>{this.state.totalProjects}</h2>
              </div>
            </AnimationOnScroll>

            <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce>
              <div className="ct-stats-container">
                <span className="fas fa-funnel-dollar"></span>
                <h1>Collected Investment</h1>
                <h2>{Intl.NumberFormat().format(this.state.totalInvestment)} USD</h2>
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce>
              <div className="ct-stats-container">
                <span className="fas fa-users"></span>
                <h1>Active Users</h1>
                <h2>{this.state.totalUsers}</h2>
              </div>
            </AnimationOnScroll>
            <AnimationOnScroll animateIn="animate__fadeInDown" animateOnce>
              <div className="ct-stats-container">
                <span className="fas fa-hand-holding-usd"></span>
                <h1>Collected profit</h1>
                <h2>{this.state.totalProfit} USD</h2>
              </div>
            </AnimationOnScroll>
            <div className="ct-chart-container">
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                  width={500}
                  height={400}
                  data={this.state.chartData}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <Area type="monotone" dataKey="uv" stroke="rgba(255, 255, 255, 0)" fill="#fff" />
                </AreaChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
