import Web3 from "web3";
import { APIPoolDataInterface, ProjectDataInterface } from "../../../core/types";
import React, { useEffect, useRef } from "react";
import { LineChart, Line, ResponsiveContainer, AreaChart, CartesianGrid, Area } from "recharts";

import "./descriptionItem.css";
import { Link } from "react-router-dom";

interface ProjectDescriptionProps {
  item: APIPoolDataInterface;
  onChanging: boolean;
}

const chartData = [
  {
    name: "Page A",
    uv: 100,
  },
  {
    name: "Page B",
    uv: 150,
  },
  {
    name: "Page C",
    uv: 350,
  },
  {
    name: "Page D",
    uv: 150,
  },
  {
    name: "Page E",
    uv: 500,
  },
  {
    name: "Page F",
    uv: 650,
  },
  {
    name: "Page G",
    uv: 400,
  },
];

export class ProjectDescriptionItem extends React.Component<ProjectDescriptionProps> {
  chartContainerRef: any;

  constructor(props: ProjectDescriptionProps) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div
        id={this.props.item.poolData.title}
        key={this.props.item.poolData.title}
        className={"ct-project-description ct-max-container animate__animated" + (this.props.onChanging ? " animate__fadeOut" : " animate__fadeIn")}
      >
        <div className="ct-image-container">
          <img src={this.props.item.poolData.image} alt="" />
        </div>
        <div className="ct-profit-container">
          <h4> {this.props.item.poolData.title}</h4>
          <small>Investment pool</small>
          <p>{this.props.item.poolData.smallDescription}</p>
          <button className="button-29 ct-mt-20">
            <Link to={"/pool/" + this.props.item._id}>Read more</Link>{" "}
          </button>

          <div className="ct-chart-container">
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                width={500}
                height={400}
                data={chartData}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <Area type="monotone" dataKey="uv" stroke="white" fill="#46A9FA" />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    );
  }
}
