import { AppData } from "../../../core/types";
import { AppActions } from "../../types";

export const toggleLeftNavigation = (force?: boolean) => {
  return { type: AppActions.TOGGLE_LEFT_NAVIGATION, force };
};

export const toggleLoader = (force?: boolean) => {
  return { type: AppActions.TOGGLE_LOADER, force };
};

export const changeEmployeesPagination = (page: number) => {
  return { type: AppActions.CHANGE_EMPLOYEES_PAGINATION, page };
};

export const saveAppData = (data: AppData) => {
  return { type: AppActions.SAVE_APP_DATA, data };
};

export const setProviderError = (error: boolean) => {
  return { type: AppActions.SET_PROVIDER_ERROR, error };
};

export const setNetworksError = (error: boolean) => {
  return { type: AppActions.SET_NETWORK_ERROR, error };
};

export const setAvailableBlockchains = (networks: string[]) => {
  return { type: AppActions.SET_AVAILABLE_NETWORKS, networks };
};
