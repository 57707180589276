import React from "react";
import { Link } from "react-router-dom";
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, XAxis, Tooltip, LineChart, Line } from "recharts";
import Web3 from "web3";
import { appConfig } from "../../../core/app";
import { CrowdFunding } from "../../../core/contracts/crowdFunding";
import { APIPoolDataInterface, APIUserPoolInfo, PoolDataInterface } from "../../../core/types";
import { BlockChainState } from "../../../storage/state/blockChain/state";
import ItemList from "../items/dashboard_item";

import PoolContractAbi from "../../../assets/contracts/CrowdFundingController.json";
import TokenContractAbi from "../../../assets/contracts/Token.json";
import { Token } from "../../../core/contracts/token";
import { spawn } from "child_process";

import "./listItem.css";
import { UtilsHelpers } from "../../../core/helpers/utils";

interface PoolListItemProps {
  pool: APIPoolDataInterface;
  blockChain: BlockChainState;
}

interface PoolListItemUserProps {
  pool: APIPoolDataInterface;
  user: APIUserPoolInfo;
  blockChain: BlockChainState;
}

interface PoolListItemState {}

export class PoolListItem extends React.Component<PoolListItemProps, PoolListItemState> {
  constructor(props: PoolListItemProps) {
    super(props);

    this.state = {};
  }

  componentDidMount(): void {}

  render() {
    const anualProfit = (
      ((this.props.pool.poolData?.weeklyRewards / 7) * 365 * 100) /
      Number(Web3.utils.fromWei(this.props.pool.funding.goal))
    ).toFixed(2);

    return (
      <div className="ct-pool-list-item">
        <ItemList
          title={
            <div className="ct-title">
              <h4>
                {this.props.pool.poolData?.title}{" "}
                {this.props.pool.poolData.test ? <strong className="ct-small-testnet-pool"> Testnet pool</strong> : ""}
              </h4>
              <small>{this.props.pool.poolData?.subtitle}</small>
            </div>
          }
          customActions={
            <React.Fragment>
              {this.props.pool.poolData?.currency ? (
                <Link className="ct-accordion-action " to={"/pool/" + this.props.pool._id}>
                  <img className="ct-network-icon" src={UtilsHelpers.getTokenImageBySymbol(this.props.pool.poolData?.currency)} alt="" />
                </Link>
              ) : (
                ""
              )}
            </React.Fragment>
          }
          data={
            <div className="ct-data">
              <span>
                <strong>Est. APR</strong> {anualProfit} %
              </span>
              <span>
                <strong>Users</strong> {this.props.pool.totalUsers}
              </span>
              <span>
                <strong>Filled</strong> {Number(Web3.utils.fromWei(this.props.pool.funding.currentAmount)).toFixed(2)}{" "}
                {this.props.pool.poolData?.currency}
              </span>
              <span>
                <strong>Goal</strong> {Number(Web3.utils.fromWei(this.props.pool.funding.goal)).toFixed(2)} {this.props.pool.poolData?.currency}
              </span>
            </div>
          }
          internal={
            <div className="ct-pool-data">
              <div className="ct-admin-data">
                <div className="ct-pool-info">
                  <h4>{this.props.pool.poolData?.title}</h4>
                  <div className="ct-pool-desc" dangerouslySetInnerHTML={{ __html: this.props.pool.poolData?.description }}></div>
                  <Link to={"/pool/" + this.props.pool._id} className="button-29 button-small ct-mt-10">
                    Invest now
                  </Link>
                  <div className="ct-data-container">
                    <div className="ct-data-flush">
                      <span>
                        <strong>Estimated APR:</strong> {anualProfit} %
                      </span>
                      <span>
                        <strong>Estimated weekly rewards:</strong> {(this.props.pool.poolData?.weeklyRewards || 0).toFixed()}{" "}
                        {this.props.pool.poolData?.currency}
                      </span>
                      <span>
                        <strong>Users:</strong> {this.props.pool.totalUsers}
                      </span>
                      <span>
                        <strong>Filled:</strong>{" "}
                        {this.props.pool?.funding.currentAmount
                          ? Number(Web3.utils.fromWei(this.props.pool?.funding.currentAmount?.toString())).toFixed(2)
                          : 0}{" "}
                        {this.props.pool.poolData?.currency}
                      </span>
                      <span>
                        <strong>Goal:</strong> {this.props.pool.funding.goal ? Web3.utils.fromWei(this.props.pool.funding.goal?.toString()) : 0}{" "}
                        {this.props.pool.poolData?.currency}
                      </span>
                      <span>
                        <strong>Distributed rewards:</strong>{" "}
                        {this.props.pool.injectedRewards ? Web3.utils.fromWei(this.props.pool.totalProfit) : this.props.pool.totalProfit}{" "}
                        {this.props.pool.poolData?.currency}
                      </span>
                    </div>
                    <div className="ct-links">
                      <a href={this.props.pool.poolData.documentation || "https://blockraising.xyz"} target="_blank" rel="noopener noreferrer">
                        Documentation <span className="fas fa-passport"></span>
                      </a>
                      <a href={this.props.pool.poolData.adminLink || "https://blockraising.xyz"} target="_blank" rel="noopener noreferrer">
                        Admin transactions <span className="fas fa-address-book"></span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

export class PoolListUserItem extends React.Component<PoolListItemUserProps, PoolListItemState> {
  constructor(props: PoolListItemUserProps) {
    super(props);

    this.state = {};
  }

  componentDidMount(): void {}

  render() {
    const anualProfit = (
      ((this.props.pool.poolData?.weeklyRewards / 7) * 365 * 100) /
      Number(Web3.utils.fromWei(this.props.pool.funding.goal))
    ).toFixed(2);

    const poolPercentage = this.props.user.contractData ? Number(this.props.user.contractData?.data?.percentage || 0) / 100000 : 0;

    return (
      <div className="ct-pool-list-item">
        <ItemList
          title={
            <div className="ct-title">
              <h4>{this.props.pool.poolData?.title}</h4>
              <small>{this.props.pool.poolData?.subtitle}</small>
            </div>
          }
          customActions={
            <React.Fragment>
              {this.props.pool.poolData?.currency ? (
                <Link className="ct-accordion-action " to={"/pool/" + this.props.pool._id}>
                  <img className="ct-network-icon" src={UtilsHelpers.getTokenImageBySymbol(this.props.pool.poolData?.currency)} alt="" />
                </Link>
              ) : (
                ""
              )}
            </React.Fragment>
          }
          data={
            <div className="ct-data">
              <span>
                <strong>APR</strong> {anualProfit} %
              </span>
              <span>
                <strong>Pofit</strong> {Web3.utils.fromWei(this.props.user?.contractData?.availableProfit || "0")}{" "}
                {this.props.pool.poolData?.currency}
              </span>
              <span>
                <strong>Investment</strong> {Web3.utils.fromWei(this.props.user?.contractData?.data?.balance || "0")}{" "}
                {this.props.pool.poolData?.currency}
              </span>
              <span>
                <strong>Participation</strong> {poolPercentage} %
              </span>
            </div>
          }
          internal={
            <div className="ct-pool-data">
              <div className="ct-admin-data">
                <div className="ct-pool-info">
                  <h4>{this.props.pool.poolData?.title}</h4>

                  <div className="ct-data-container">
                    <div className="ct-data-flush">
                      <span>
                        <strong>Estimated APR:</strong> {anualProfit} %
                      </span>
                      <span>
                        <strong>Estimated weekly rewards:</strong>{" "}
                        {((Number(this.props.pool?.poolData?.weeklyRewards || 0) * poolPercentage) / 100).toFixed(2)}{" "}
                        {this.props.pool?.poolData?.currency}
                      </span>
                      <span>
                        <strong>Pool participation:</strong> {poolPercentage} %
                      </span>
                      <span>
                        <strong>Total investment:</strong> {Web3.utils.fromWei(this.props.user?.contractData?.data?.balance || "0")}{" "}
                        {this.props.pool?.poolData?.currency}
                      </span>
                      <span>
                        <strong>Distributed rewards:</strong>{" "}
                        {(Number(Web3.utils.fromWei(this.props.pool?.totalProfit || "0")) * poolPercentage) / 100}{" "}
                        {this.props.pool.poolData?.currency}
                      </span>
                      <span>
                        <strong>Extracted rewards:</strong>{" "}
                        {Number(Web3.utils.fromWei(this.props.user.contractData?.data?.profitWithdraw || "0")).toFixed(2)}{" "}
                        {this.props.pool?.poolData?.currency}
                      </span>
                      <span>
                        <strong>Available profit:</strong>{" "}
                        {Number(Web3.utils.fromWei(this.props.user?.contractData?.availableProfit || "0")).toFixed(2)}{" "}
                        {this.props.pool.poolData?.currency}
                      </span>
                    </div>
                    <div className="ct-links">
                      <a href={this.props.pool.poolData.documentation || "https://blockraising.xyz"} target="_blank" rel="noopener noreferrer">
                        Documentation <span className="fas fa-passport"></span>
                      </a>
                      <a href={this.props.pool.poolData.adminLink || "https://blockraising.xyz"} target="_blank" rel="noopener noreferrer">
                        Admin transactions <span className="fas fa-address-book"></span>
                      </a>
                    </div>
                  </div>

                  <Link to={"/pool/" + this.props.pool._id} className="button-29 button-small ct-mt-10">
                    Go to pool
                  </Link>
                </div>
              </div>
            </div>
          }
        />
      </div>
    );
  }
}
