import { useState } from "react";
import "./footer.styles.css";

export const Footer = () => {
  return (
    <div className="ct-footer">
      <div className="ct-big-info-container ct-max-container">
        <div className="ct-big-info">
          <a target="_blank" rel="noopener noreferrer" href="https://docs.blockraising.xyz/">
            <span className="fas fa-passport"></span>
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://t.me/blockraising/">
            <span className="fab fa-telegram"></span>
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/blockraising">
            <span className="fab fa-twitter"></span>
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://instagram.com/blockraising">
            <span className="fab fa-instagram"></span>
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://medium.com/blockraising">
            <span className="fab fa-medium"></span>
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://reddit.com/blockraising">
            <span className="fab fa-reddit"></span>
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://discord.com/blockraising">
            <span className="fab fa-discord"></span>
          </a>
        </div>
        <div className="ct-links">
          <span>2023 blockraising.xyz By Blockraising</span>
          <a href="mailto: admin@blockraising.xyz">admin@blockraising.xyz</a>
        </div>
      </div>
    </div>
  );
};
