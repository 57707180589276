import { appConfig, AppMode } from "../app";

import MTRGIcon from "../../assets/images/icons/MTRG.png";
import MATICIcon from "../../assets/images/icons/MATIC.webp";
import ETHIcon from "../../assets/images/icons/ETH.png";
import BinanceIcon from "../../assets/images/icons/BINANCE.png";
import MumbaiIcon from "../../assets/images/icons/MUMBAI.png";

export class UtilsHelpers {
  static toHexadecimal(number: string) {
    if (!isNaN(Number(number))) return parseInt(number, 16).toString();
    else console.log("[UTILS] To Hexadecimal error.");
  }

  static toUTCTime(date: Date) {
    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  }

  static normalizeWei(wei: string) {
    return !isNaN(Number(wei)) ? Number(wei) / Math.pow(10, 18) : NaN;
  }

  static getTokenPricePerSymbol(symbol: string, prices: any) {
    switch (symbol) {
      case "MATIC":
        return prices["matic-network"].usd;
      case "BNB":
        return prices["binancecoin"].usd;
      case "ETHER":
        return prices["ethereum"].usd;
      case "MTRG":
        return prices["meter"].usd;
      default:
        return prices["matic-network"].usd;
    }
  }

  static debugger(log: string) {
    console.warn("[DEBUG] " + log);
  }

  static parseSeconds(timeInSeconds: number) {
    const minutes = timeInSeconds / 60;
    const hours = minutes / 60;
    const days = hours / 24;

    return timeInSeconds < 60
      ? timeInSeconds.toFixed() + " Seconds"
      : minutes < 60
      ? minutes.toFixed() + " Minutes"
      : hours < 48
      ? hours.toFixed() + " Hours"
      : days.toFixed() + " Days";
  }

  static timestampToHours(miliseconds: number) {
    return (miliseconds / 1000 / 60 / 60).toFixed(1);
  }

  static parseMoney(money: number) {
    return Intl.NumberFormat().format(money);
  }

  static getTokenImageBySymbol(symbol: string) {
    switch (symbol) {
      case "MTRG":
      case "MTR":
      case "TMTRG":
        return MTRGIcon;
      case "MATIC":
        return MATICIcon;
      case "MUMBAI":
        return MumbaiIcon;
      case "BNB":
        return BinanceIcon;
      case "ETH":
        return ETHIcon;
      default:
        return MATICIcon;
    }
  }

  static getChainSymbolById(id: string) {
    switch (id) {
      case "80001":
      case "137":
        return "MATIC";
      case "56":
        return "BNB";
      case "82":
        return "MTRG";
      case "83":
        return "TMTRG";
    }
  }

  static getChainNameById(chainId: string) {
    switch (chainId) {
      case "80001":
        return "MUMBAI";
      case "137":
        return "Polygon";
      case "56":
        return "BNB Chain";
      case "82":
        return "Meter";
      case "83":
        return "Test Meter";
      default:
        return "MUMBAI";
    }
  }

  static getTokenSymbolByAdress(address: string) {
    switch (address.toLocaleLowerCase()) {
      //Polygon
      case "0x8D8190931173A3B28EF8B9C4106Faf9771Aa105c".toLocaleLowerCase():
        return "MATIC"; // Is FTB on the testnet
      case "0x228ebBeE999c6a7ad74A6130E81b12f9Fe237Ba3".toLocaleLowerCase():
      case "0x8a419ef4941355476cf04933e90bf3bbf2f73814".toLocaleLowerCase():
        return "MTRG"; // Is MTRG on the testnet
      default:
        return "?";
    }
  }

  static getCollectionNameByAddress(address: string) {
    switch (address.toLocaleLowerCase()) {
      default:
        return "?";
    }
  }

  static getCollectionImageBySymbol(symbol: string) {
    switch (symbol) {
      default:
        return null;
    }
  }

  static getCollectionImageByAddress(address: string) {}

  static shuffle(array: any[]) {
    let currentIndex = array.length,
      randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
  }

  static validateFiles(files: File[], validType: string[]) {
    let isValid = true;

    for (let i = 0; i < files.length; i++) {
      let validated = false;

      for (let j = 0; j < validType.length; j++) {
        if (files[i].type === validType[j]) {
          validated = true;
          break;
        }
      }

      if (validated === false) {
        isValid = false;
        break;
      }
    }

    return isValid;
  }

  static validateFilesSize(files: File[], maxSize: number) {
    let isValid = true;

    for (let i = 0; i < files.length; i++) {
      if (files[i].size > maxSize) {
        isValid = false;
        break;
      }
    }

    return isValid;
  }
}
