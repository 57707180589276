import React, { Component } from "react";
import Slider from "react-slick";
import SliderItem from "../items/items";
import "./sliderOne.css";
import Carousel from "react-material-ui-carousel";
import { ProjectDescriptionItem } from "../items/descriptionItem";
import { Waves3 } from "../../molecules/waves/waves3";
import { ApiHelpers } from "../../../core/helpers/api";

const Arrow = (props: any) => {
  const { onClick } = props;

  return (
    <div className={"ct-carousel-icon" + (" ct-" + props.type)} style={{ display: "flex" }} onClick={onClick}>
      {props.type === "prev" ? <span className="fas fa-arrow-left"></span> : <span className="fas fa-arrow-right"></span>}
    </div>
  );
};

interface SliderProps {}

interface SlideraState {
  activeSlider: number;
  slidersData: any[];
  onChanging: boolean;
}

export default class SwipeToSlide extends Component<SliderProps, SlideraState> {
  constructor(props: SliderProps) {
    super(props);

    this.state = {
      activeSlider: 0,
      onChanging: false,
      slidersData: [],
    };
  }

  componentDidMount(): void {
    this.loadPoolsInformation();
  }

  async loadPoolsInformation() {
    ApiHelpers.getPools()
      .then((data) => {
        if (data && !data.error && Array.isArray(data.data)) {
          this.setState({ slidersData: data.data });
        } else throw new Error("Invalid API information");
      })
      .catch((error) => console.log(error));
  }

  render() {
    const settings = {
      className: "slider variable-width",
      variableWidth: true,
      infinite: true,
      swipeToSlide: true,
      //slidesToShow: this.state.slidersData.length > 3 ? 2 : this.state.slidersData.length,
      nextArrow: <Arrow type={"next"} />,
      prevArrow: <Arrow type={"prev"} />,
      autoplaySpeed: 5000,
      autoPlay: true,
      centerMode: true,
      afterChange: (current: number) => this.setState({ activeSlider: current, onChanging: false }),
      beforeChange: () => this.setState({ onChanging: true }),
    };

    const selectedSlider = this.state.slidersData[this.state.activeSlider];

    return (
      <div className="ct-main-sliders-container">
        <div className="ct-sliderOne">
          <Slider {...settings}>
            {Array.isArray(this.state.slidersData)
              ? this.state.slidersData.map((item, index) => {
                  return (
                    <SliderItem
                      key={index}
                      title={item.poolData.title}
                      image={item.poolData.image}
                      progress={item.funding.currentAmount}
                      goal={item.funding.goal}
                      description={item.poolData.smallDescription}
                      currency={item.poolData.currency}
                      activeSlider={this.state.activeSlider === index}
                    ></SliderItem>
                  );
                })
              : ""}
          </Slider>
        </div>
        {this.state.slidersData[this.state.activeSlider] ? <ProjectDescriptionItem item={selectedSlider} onChanging={this.state.onChanging} /> : ""}
      </div>
    );
  }
}
