import React from "react";
import NetworksGif from "../../../assets/images/icons/NETWORKS_GIF.gif";
import SocialsGif from "../../../assets/images/icons/SOCIAL_GIF.gif";

import "./main.css";

export const FullTimeline = () => {
  return (
    <React.Fragment>
      <h4 className="timeline__title">Roadmap</h4>
      <div className="timeline">
        <div className="timeline__event  animated fadeInUp delay-3s timeline__event--type1">
          <div className="timeline__event__icon ">
            <img src={NetworksGif} alt="" />
          </div>
          <div className="timeline__event__date">Q1 2023</div>
          <div className="timeline__event__content ">
            <div className="timeline__event__title">Testnet implemementation</div>
            <div className="timeline__event__description">
              <p>
                Deploying an application in multiple blockchains can be a complex process that involves careful planning, development, testing, and
                deployment. The plan is create an scalable DAPP to deploy and integrate our application in all available networks.
              </p>
            </div>
          </div>
        </div>

        <div className="timeline__event animated fadeInUp delay-2s timeline__event--type2">
          <div className="timeline__event__icon">
            <img src={SocialsGif} alt="" />
          </div>
          <div className="timeline__event__date">Q2 2023</div>
          <div className="timeline__event__content">
            <div className="timeline__event__title">Social media growing</div>
            <div className="timeline__event__description">
              <p>
                Growing social media involves several steps, including defined goals, identifying target audiences, selecting platforms, developing a
                content strategy, and measuring results.
              </p>
            </div>
          </div>
        </div>

        <div className="timeline__event animated fadeInUp delay-2s timeline__event--type2">
          <div className="timeline__event__icon"></div>
          <div className="timeline__event__date">Q2 2023</div>
          <div className="timeline__event__content">
            <div className="timeline__event__title">Hackathon</div>
            <div className="timeline__event__description">
              <p>
                We will participate in different competitions in order to obtain initial capital to invest in the future of our project, a large part
                of the profits will be invested in future investment pools and marketing.
              </p>
            </div>
          </div>
        </div>

        <div className="timeline__event animated fadeInUp delay-1s timeline__event--type3">
          <div className="timeline__event__icon"></div>
          <div className="timeline__event__date">Q3 2023</div>
          <div className="timeline__event__content">
            <div className="timeline__event__title">Launch the first investment pool</div>
            <div className="timeline__event__description">
              <p>
                After doing all the tests and preparing our audience, we will launch the first investment pool in which only our private investors
                will be able to participate.
              </p>
            </div>
          </div>
        </div>

        <div className="timeline__event animated fadeInUp delay-1s timeline__event--type3">
          <div className="timeline__event__icon"></div>
          <div className="timeline__event__date">Q3 2023</div>
          <div className="timeline__event__content">
            <div className="timeline__event__title">Statistics center</div>
            <div className="timeline__event__description">
              <p>
                One of the main purposes of the project is to be as transparent as the blckchain so we will implement tracking mechanisms for the
                whole system and also accounting mechanisms so that users have all the information at their disposal.
              </p>
            </div>
          </div>
        </div>

        <div className="timeline__event animated fadeInUp delay-1s timeline__event--type3">
          <div className="timeline__event__icon"></div>
          <div className="timeline__event__date">Q3 2023</div>
          <div className="timeline__event__content">
            <div className="timeline__event__title">Promote our first pool</div>
            <div className="timeline__event__description">
              <p>
                Our first pool will attract new investors and allow us to reach new investors, we will use our social networks to count the power and
                reach that our new pool will have.
              </p>
            </div>
          </div>
        </div>

        <div className="timeline__event animated fadeInUp timeline__event--type1">
          <div className="timeline__event__icon"></div>
          <div className="timeline__event__date">Q4 2023</div>
          <div className="timeline__event__content">
            <div className="timeline__event__title">Investment tools</div>
            <div className="timeline__event__description">
              <p>
                To grow smoothly we need to create tools to help us find new investments and keep launching new investment pools, the plan is to
                launch our first tool to help investors and blockraising with the constant growth.
              </p>
            </div>
          </div>
        </div>

        <div className="timeline__event animated fadeInUp timeline__event--type2">
          <div className="timeline__event__icon"></div>
          <div className="timeline__event__date">Q1 2024</div>
          <div className="timeline__event__content">
            <div className="timeline__event__title">Grow with blockchain partnerships</div>
            <div className="timeline__event__description">
              <p>
                After the whole process we will be able to launch our project in other blockchains creating strategic alliances to find new
                investments and incentives.
              </p>
            </div>
          </div>
        </div>
        <div className="timeline__event animated fadeInUp timeline__event--type2">
          <div className="timeline__event__icon"></div>
          <div className="timeline__event__date">Q3 2024</div>
          <div className="timeline__event__content">
            <div className="timeline__event__title">Launch our own token</div>
            <div className="timeline__event__description">
              <p>
                We believe this is the right process to launch our token after a year of work looking for and finding new investors. Our token will be
                a great incentive to invest in all of our investment pools and will have deflationary mechanisms implementing the profits from our
                investments in buy and burn systems.
              </p>
            </div>
          </div>
        </div>

        <div className="timeline__event animated fadeInUp timeline__event--type2">
          <div className="timeline__event__icon"></div>
          <div className="timeline__event__date">Q3 2024</div>
          <div className="timeline__event__content">
            <div className="timeline__event__title">Implement the token rewards on all pools</div>
            <div className="timeline__event__description">
              <p>
                The contracts of our investment pools will support the addition of new rewards, this means that since the beginning of our project we
                thought about scaling up more and more to provide a better experience to investors.
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
