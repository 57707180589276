import { APIPoolDataInterface, APIUserPoolInfo, PoolDataInterface } from "../../../core/types";
import { BlockChainState } from "../../../storage/state/blockChain/state";
import { PoolListItem, PoolListUserItem } from "./listItem";

import "./list.css";

export const PoolsList = (props: { pools: APIPoolDataInterface[]; blockChain: BlockChainState }) => {
  return (
    <div className="ct-pools-list">
      {props.pools
        .sort((a, b) => Number(a.poolData.test) - Number(b.poolData.test))
        .map((pool, index) => {
          return <PoolListItem blockChain={props.blockChain} pool={pool} key={index} />;
        })}
    </div>
  );
};

export const UsersPoolsList = (props: { pools: APIPoolDataInterface[]; blockChain: BlockChainState; userInfo: APIUserPoolInfo[] }) => {
  return (
    <div className="ct-pools-list">
      {props.pools
        .sort((a, b) => Number(a.poolData.test) - Number(b.poolData.test))
        .map((pool, index) => {
          return <PoolListUserItem user={props.userInfo[index]} blockChain={props.blockChain} pool={pool} key={index} />;
        })}
    </div>
  );
};
