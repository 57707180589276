import "./waves3.css";

export const Waves3 = () => {
  return (
    <div className="ct-waves3-inverted">
      <div className="ocean3-inverted">
        <div className="wave3-inverted"></div>
        <div className="wave3-inverted"></div>
      </div>
      <div className="ocean3-inverted">
        <div className="wave3-inverted"></div>
        <div className="wave3-inverted"></div>
      </div>
    </div>
  );
};
