import { RouteComponentProps, withRouter } from "react-router";
import React, { useEffect, useRef, useState } from "react";
import { Customer } from "../../../core/customer";
import { Link } from "react-router-dom";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import MetamaskIcon from "../../../assets/images/icons/METAMASK.png";

import { UtilsHelpers } from "../../../core/helpers/utils";
import { FormControl } from "@mui/material";
import { BlockChainHelpers } from "../../../core/helpers/chain";

import "./top.styles.css";
import { ApplicationState } from "../../../storage/state/app/state";
import { blockChainReducer } from "../../../storage/state/blockChain/state";

interface TopNavigationProps {
  onToggleLeftNav: () => void;
  onLoadBlockChain: () => void;
  onLoadCustomerData: () => void;
  onToggleLoader: (force: boolean) => void;
  setAvailableNetworks: (networks: string[]) => void;
  setProviderError: (error: boolean) => void;
  setNetworksError: (error: boolean) => void;
  appState: ApplicationState;
  customer: Customer | null;
}

const colorBasedOnUserDown = (element: HTMLElement) => {
  element.style.backgroundColor = "rgba(9, 142, 252, " + (window.scrollY * 100) / 100000 + ")";
  element.style.boxShadow = "0 3px 6px rgb(0 0 0 / " + (window.scrollY * 100) / 10000 + "%)";
};

const isFocusedNavigation = (url: string) => {
  return ["pool", "pools", "user-profile", "roadmap"].includes(url);
};

const willNeedBlockChain = (url: string) => {
  return ["pool", "user-profile"].includes(url);
};

const UnlinkTopNavigation = (props: TopNavigationProps & RouteComponentProps) => {
  const [blockchain, setBlockchain] = useState("");
  const [isFocused, setFocused] = useState(isFocusedNavigation(props.location.pathname.split("/")[1]));
  const [willNeedConnection, setBlockChainSelector] = useState(willNeedBlockChain(props.location.pathname.split("/")[1]));
  const topNavbarElement = useRef<any>();
  const availableBlockchains = useRef<string[]>();

  const connectToBlockchain = (id: string) => {
    BlockChainHelpers.validateConnection().then((connected) =>
      connected
        ? BlockChainHelpers.changeEthereumChain(id).then(() => {
            props.onLoadBlockChain();
            props.onLoadCustomerData();
          })
        : null
    );
  };

  const changeBlockchain = (chainId: string) => {
    setBlockchain(chainId);
    BlockChainHelpers.changeEthereumChain(chainId);
  };

  useEffect(() => {
    const routerListener = props.history.listen(() => {
      window.scrollTo(0, 0);

      if (props.history.location.pathname !== props.location.pathname) {
        props.setNetworksError(false);
        props.setProviderError(false);
        props.setAvailableNetworks([]);
        setFocused(isFocusedNavigation(props.history.location.pathname.split("/")[1]));
        setBlockChainSelector(willNeedBlockChain(props.history.location.pathname.split("/")[1]));
      }
    });

    const validateNavbarAnimation = () => {
      if (topNavbarElement.current && !isFocusedNavigation(props.location.pathname.split("/")[1])) {
        colorBasedOnUserDown(topNavbarElement.current as HTMLElement);
      }
    };

    const provider = BlockChainHelpers.getProvider();

    if (provider) {
      provider.eth.getChainId().then((chainId) => {
        if (props.appState.availableNetworks.includes("0x" + chainId.toString(16))) setBlockchain("0x" + chainId.toString(16));
        else setBlockchain(props.appState.availableNetworks[0]);
      });
    }

    availableBlockchains.current = props.appState.availableNetworks;
    window.addEventListener("scroll", validateNavbarAnimation, false);

    return () => {
      if (routerListener) routerListener();
      window.removeEventListener("scroll", validateNavbarAnimation, false);
    };
  }, [props, props.history]);

  return (
    <React.Fragment>
      <div ref={topNavbarElement} className={"ct-top-nav" + (isFocused ? " ct-focused-navigation" : "")}>
        <div className="ct-max-container">
          <div className="ct-title">
            <img src="/favicon.ico" alt="" /> <h1>Blockraising</h1>
          </div>

          <div className="ct-links">
            <Link to={"/"}>home</Link>
            <Link to={"/roadmap"}>roadmap</Link>
            <Link to={"/pools"}>pools</Link>
            <Link to={"/user-profile"}>account</Link>

            {willNeedConnection && blockchain ? (
              <div className="ct-chain-selector">
                <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
                  <Select
                    labelId="select-blockchain-label"
                    id="select-blockchain"
                    value={blockchain}
                    inputProps={{ "aria-label": "Without label" }}
                    className="ct-chain-selector-wrapper"
                    onChange={(e) => changeBlockchain(e.target.value)}
                  >
                    {props.appState.availableNetworks.map((network, index) => {
                      const networkData = BlockChainHelpers.findChainDataById(network);

                      return (
                        <MenuItem key={index} value={network} className="ct-chain">
                          <img
                            className="ct-chain-icon"
                            src={UtilsHelpers.getTokenImageBySymbol(networkData?.nativeCurrency?.symbol || "")}
                            alt={UtilsHelpers.getTokenImageBySymbol(networkData?.nativeCurrency?.symbol || "")}
                          />
                          <span className="ct-chain-text">{networkData?.chainName}</span>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </div>
            ) : (
              ""
            )}

            {/* <div className="ct-actions">
            <span
              onClick={() => props.onToggleLeftNav()}
              className="ct-left-toggler fas fa-bars"
            ></span>
          </div> */}
          </div>
        </div>
      </div>

      {props.appState.networkError ? (
        <div className="ct-blockchain-error">
          <div className="ct-error-container">
            <h4>Invalid network</h4>
            <p>Connect your wallet to the correct network</p>
            <div className="ct-suggested-networks">
              {props.appState.availableNetworks.map((network, index) => {
                const networkData = BlockChainHelpers.findChainDataById(network);
                return (
                  <div key={index} className="ct-chain-container" onClick={() => connectToBlockchain(network)}>
                    <img src={UtilsHelpers.getTokenImageBySymbol(networkData?.nativeCurrency?.symbol || "")} alt={networkData?.chainName} />
                    <h5>{networkData?.chainName}</h5>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      {props.appState.providerError ? (
        <div className="ct-blockchain-error">
          <div className="ct-error-container">
            <h4>Invalid provider</h4>
            <p>Install a chain provider</p>
            <a href="https://metamask.io/download/" target="_blank" rel="noopener noreferrer" className="ct-suggested-providers">
              <div className="ct-provider-container">
                <img src={MetamaskIcon} alt="" />
                <h5>Metamask</h5>
              </div>
            </a>
          </div>
        </div>
      ) : (
        ""
      )}
    </React.Fragment>
  );
};

export const TopNavigation = withRouter(UnlinkTopNavigation);
