import React, { Component } from "react";
import { Link } from "react-router-dom";

import "./launchedProjects.css";

interface LaunchedProjectsProps {}

interface LaunchedProjectsState {}

export default class LaunchedProjects extends Component<LaunchedProjectsProps, LaunchedProjectsState> {
  dataInterval: any;

  constructor(props: LaunchedProjectsProps) {
    super(props);

    this.state = {};
  }

  componentDidMount(): void {}

  render() {
    return (
      <React.Fragment>
        <div className="ct-blue-gradient-top"></div>
        <div className="ct-launched-projects">
          <iframe src="https://projects-view.blockraising.xyz/"></iframe>
          <div className="ct-launched-container ct-max-container">
            <h4>Next projects</h4>
            <div className="ct-last-projects-list">
              <div className="ct-last-project">
                <div className="ct-project-info">
                  <h4>Quickswap staking</h4>
                  <p>Stake to earn using the users leverage to earn more profit on the new staking pools </p>
                  <span>Location: fully decentralized</span>
                  <span>TVL: 10000 USD</span>
                </div>
                <Link to="/pools">
                  <span className="fas fa-plane-departure"></span>
                </Link>
              </div>
              <div className="ct-last-project">
                <div className="ct-project-info">
                  <h4>Stable coins arbitrage</h4>
                  <p>A bot finding stable coin arbitrage opportunities</p>
                  <span>Location: Fully decentralized</span>
                  <span>TVL: 5000 USD</span>
                </div>
                <Link to="/pools">
                  <span className="fas fa-plane-departure"></span>
                </Link>
              </div>
              <div className="ct-last-project">
                <div className="ct-project-info">
                  <h4>Arbitrage between network</h4>
                  <p>A bot search arbitrage oppotunities between BNB Chain and Matic chain</p>
                  <span>Location: Fully decentralized</span>
                  <span>TVL: 8000 USD</span>
                </div>
                <Link to="/pools">
                  <span className="fas fa-plane-departure"></span>
                </Link>
              </div>
              <button className="button-29 ct-mt-10">
                <Link to="/pools">Go to launched pools</Link>{" "}
              </button>
              <h6>By google data artists</h6>
            </div>
          </div>
        </div>
        <div className="ct-blue-gradient-bottom"></div>
      </React.Fragment>
    );
  }
}
