import BackgroundImage from "../../../assets/images/background/art_1.png";
import { Waves } from "../../molecules/waves/waves";
import { Link } from "react-router-dom";
import "./mainBanner.css";

interface MainBannerProps {}

export const MainBannerComponent = (props: MainBannerProps) => {
  return (
    <div className="ct-main-banner">
      <div className="ct-background" style={{ backgroundImage: "url(" + BackgroundImage + ")" }}>
        <div className="ct-info-container">
          <div className="ct-center-info">
            <h1>Blockraising</h1>
            <p>Take the control of your investments</p>
            <button className="button-29">
              <Link to="/pools">Join now</Link>
            </button>
          </div>
        </div>
      </div>
      <Waves />
    </div>
  );
};
