import { AnyAction } from "redux";
import { Contract } from "../../../core/app";

import { ContractData, ContractsStateController } from "../../../core/contracts";

import { AppActions, AppReducer } from "../../types";

export const contractsKey = AppReducer.CONTRACTS;

export interface ContractsState {
  [Contract.CROWD_FUNDING]: ContractData | null;
  [Contract.CROWD_QUERIES]: ContractData | null;
}

export const initialState: ContractsState = {
  [Contract.CROWD_FUNDING]: null,
  [Contract.CROWD_QUERIES]: null,
};

export const contractsReducer = (state: ContractsState = initialState, action: AnyAction) => {
  switch (action.type) {
    case AppActions.SET_MULTIPLE_CONTRACT_ABI:
      return ContractsStateController.setMultipleContractsAbi(state, action.contracts);
    default:
      return state;
  }
};
